import axios from "axios";

const fetchUploadedDocuments = async (dealId, companyName) => {
  // console.log("Fetching files");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/sharepoint-documents/check-and-fetch`,
      {
        dealId,
        companyName,
      }
    );

    // console.log("Fetched uploaded documents: ", response.data.documentDetails);

    if (response.status === 200 && response.data.success) {
      // Ensure correct data structure is returned
      return {
        success: true,
        data: response.data.documentDetails, // Return documentDetails, not data
      };
    } else {
      return {
        success: false,
        message: response.data.message || "Failed to fetch documents.",
      };
    }
  } catch (error) {
    console.error("Error fetching documents:", error.message);
    return {
      success: false,
      message: "An error occurred while fetching documents.",
    };
  }
};

export default fetchUploadedDocuments;

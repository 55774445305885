// services/validateAccountToken.js
import axios from 'axios';

const validateAccountToken = async (token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/validate-account-token`, { token });
    return response.data;
  } catch (error) {
    console.error('Error validating account token:', error);
    throw new Error('Token validation failed');
  }
};

export default validateAccountToken;

// src/redux/slices/dealSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dealId: null, // Initially, the deal ID is null
};

const dealSlice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    setDealId: (state, action) => {
      // console.log("Setting deal ID in Redux:", action.payload);
      state.dealId = action.payload; // Set the deal ID
    },
    clearDealId: (state) => {
      // console.log("Clearing deal ID in Redux");
      state.dealId = null; // Clear the deal ID
    },
  },
});


// Export the actions for dispatching
export const { setDealId, clearDealId } = dealSlice.actions;

// Export the reducer to add to the store
export default dealSlice.reducer;

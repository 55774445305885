import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { setActiveComponent } from "../../redux/slices/activeComponentSlice";
import {
  setSidebarData as setReduxSidebarData,
  setSidebarLoading,
  setSidebarError,
} from "../../redux/slices/sidebarSlice";
import fetchSidebarData from "../../services/fetchSidebarData";
import "./Sidebar.css";

const Sidebar = ({
  franchiseId,
  stateId,
  requiredReferences = [],
  receivedReferences = [],
  setCrmReference,
}) => {
  const dispatch = useDispatch();
  const [openSections, setOpenSections] = useState({});
  const [localSidebarData, setLocalSidebarData] = useState([]);
  const [localLoading, setLocalLoading] = useState(true);

  const userRole = useSelector((state) => state.auth.user?.role);
  const sidebarData = useSelector((state) => state.sidebar.data);
  const loading = useSelector((state) => state.sidebar.loading);
  const error = useSelector((state) => state.sidebar.error);

  // Refs to track previous dependencies to avoid unnecessary re-fetching
  const previousFranchiseId = useRef(null);
  const previousStateId = useRef(null);
  const previousReferences = useRef([]);

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
  };

  // Debounce the dependencies
  const [debouncedFranchiseId] = useDebounce(franchiseId, 300);
  const [debouncedStateId] = useDebounce(stateId, 300);
  const [debouncedReferences] = useDebounce(
    [...requiredReferences, ...receivedReferences],
    300
  );

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        dispatch(setSidebarLoading(true));
        setLocalLoading(true);

        if (debouncedReferences.length === 0) {
          console.warn("No CRM references available yet. Skipping fetch.");
          dispatch(setSidebarLoading(false));
          setLocalLoading(false);
          return;
        }

        // Fetch sidebar data
        const fetchedData = await fetchSidebarData(
          debouncedFranchiseId,
          debouncedStateId,
          debouncedReferences
        );

        dispatch(setReduxSidebarData(fetchedData));
        setLocalSidebarData(fetchedData);
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
        dispatch(setSidebarError("Failed to fetch sidebar data."));
      } finally {
        dispatch(setSidebarLoading(false));
        setLocalLoading(false);
      }
    };

    const crmReferencesReady = debouncedReferences.length > 0;

    // Determine if we should fetch
    const shouldFetchData =
      crmReferencesReady &&
      (debouncedFranchiseId !== previousFranchiseId.current ||
        debouncedStateId !== previousStateId.current ||
        !arraysAreEqual(debouncedReferences, previousReferences.current));

    // Fetch only if conditions are met
    if (shouldFetchData && debouncedFranchiseId && debouncedStateId) {
      fetchDocuments();

      // Update refs to track current state
      previousFranchiseId.current = debouncedFranchiseId;
      previousStateId.current = debouncedStateId;
      previousReferences.current = debouncedReferences;
    }
  }, [debouncedFranchiseId, debouncedStateId, debouncedReferences, dispatch]);

  const toggleSection = (section) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  const handleItemClick = async (
    componentName,
    documentName = "",
    filePath = "",
    crmReference = ""
  ) => {
    // Check if the component is "companyDashboard" and sidebar already has data
    if (
      componentName === "companyDashboard" &&
      stateId &&
      franchiseId &&
      sidebarData &&
      Object.keys(sidebarData).length > 0
    ) {
      dispatch(
        setActiveComponent({
          component: componentName,
          documentName,
          filePath: null,
        })
      );
      return;
    }

    // Fetch sidebar data for "companyDashboard" if no data exists
    if (componentName === "companyDashboard" && stateId && franchiseId) {
      try {
        const references = [...requiredReferences, ...receivedReferences];
        const sidebarData = await fetchSidebarData(
          franchiseId,
          stateId,
          references
        );
        dispatch(setReduxSidebarData(sidebarData));
        setLocalSidebarData(sidebarData);
      } catch (error) {
        console.error("Error refreshing dashboard data:", error);
      }
    }

    // Set the CRM reference in MainContent via the callback
    if (setCrmReference) {
      setCrmReference(crmReference);
    }

    // Safely clean up the filePath by removing `/api/` if filePath exists
    const cleanedFilePath = filePath ? filePath.replace("/api", "") : null;

    // Dispatch active component change with the cleaned filePath
    dispatch(
      setActiveComponent({
        component: componentName,
        documentName,
        filePath: cleanedFilePath,
      })
    );
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-section">
        <div
          className="sidebar-section-title"
          onClick={() => handleItemClick("companyDashboard")}
        >
          <div className="section-title-content">
            <span className="material-symbols-outlined">dashboard</span>
            <span className="sidebar-section-name">Company Dashboard</span>
          </div>
        </div>
      </div>

      <div className="sidebar-section">
        <div
          className="sidebar-section-title"
          onClick={() => handleItemClick("employeeDocumentManager")}
        >
          <div className="section-title-content">
            <span className="material-symbols-outlined">folder_shared</span>
            <span className="sidebar-section-name">Employee Demographics & Payroll Information</span>
          </div>
        </div>
      </div>

      {userRole === "admin" && (
        <div className="sidebar-section">
          <div
            className="sidebar-section-title"
            onClick={() => handleItemClick("documentManager")}
          >
            <div className="section-title-content">
              <span className="material-symbols-outlined">folder_shared</span>
              <span className="sidebar-section-name">Document Manager</span>
            </div>
          </div>
        </div>
      )}
      {userRole === "admin" && (
        <div className="sidebar-section">
          <div
            className="sidebar-section-title"
            onClick={() => handleItemClick("userManager")}
          >
            <div className="section-title-content">
              <span className="material-symbols-outlined">person</span>
              <span className="sidebar-section-name">User Manager</span>
            </div>
          </div>
        </div>
      )}
      {loading || localLoading ? (
        <div className="no-company-selected">
          Please select a company from the drop down to view the documents...
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        Object.entries(sidebarData || localSidebarData)
          .filter(
            ([sectionName]) =>
              sectionName !== "requiredReferences" &&
              sectionName !== "receivedReferences"
          ) // Filter out unwanted sections
          .map(([sectionName, sectionData], index) => (
            <div key={index} className="sidebar-section">
              <div
                className="sidebar-section-title"
                onClick={() => toggleSection(sectionName)}
              >
                <div className="section-title-content">
                  <span className="material-symbols-outlined">
                    {sectionData.icon || "folder"}
                  </span>
                  <span className="sidebar-section-name">{sectionName}</span>
                </div>
                <span className="material-symbols-outlined arrow-icon">
                  {openSections[sectionName]
                    ? "arrow_drop_up"
                    : "arrow_drop_down"}
                </span>
              </div>
              {openSections[sectionName] && (
                <ul className="sidebar-items">
                  {sectionData.items.map((item, idx) => (
                    <li
                      key={idx}
                      className="sidebar-item"
                      onClick={() =>
                        handleItemClick(
                          "documentViewer",
                          item.name,
                          item.filePath,
                          item.crmReference
                        )
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))
      )}
    </aside>
  );
};

export default Sidebar;

import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./UploadDocumentModal.css";

const UploadDocumentModal = ({ isOpen, onClose, onConfirm, isBulkUpload }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tag, setTag] = useState("");

  const handleFileChange = (event) => {
    // Store files as an array, even if only one file is selected
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handleConfirm = () => {
    if (selectedFiles.length > 0 && tag) {
      // Map tags to files or set a single tag for all files
      const tags = selectedFiles.map(() => tag);
      onConfirm(selectedFiles, tags); // Pass array of files and tags
    } else {
      alert("Please select a file and enter a tag.");
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="upload-modal-overlay">
      <div className="upload-modal-content">
        <button className="upload-close-button" onClick={onClose}>
          &times;
        </button>
        <h3>{isBulkUpload ? "Upload Bulk Documents" : "Upload Single Document"}</h3>
        <div className="upload-modal-body">
          <input
            className="upload-document-file-dialog"
            type="file"
            onChange={handleFileChange}
            multiple={isBulkUpload} // Allows multiple file selection if isBulkUpload is true
          />
          <p>Add a tag, example: "John Doe"</p>
          <input
            type="text"
            placeholder="Enter tag"
            value={tag}
            onChange={handleTagChange}
            className="upload-tag-input"
          />
        </div>
        <div className="upload-modal-actions">
          <button onClick={handleConfirm} className="upload-confirm-button">
            Confirm Upload
          </button>
          <button onClick={onClose} className="upload-cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default UploadDocumentModal;

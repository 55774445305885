import React from 'react';
import ReactDOM from 'react-dom';
import './TempModal.css';

const ESignModal = ({ isOpen, onClose, onConfirm, documentUrl }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <iframe src={documentUrl} className="pdf-viewer" title="eSign Document"></iframe>
        <div className="modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>Confirm</button>
          <button className="cancel-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default ESignModal;

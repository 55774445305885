import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DocumentActions from "./DocumentActions";
import UploadedDocumentsTable from "./UploadedDocumentsTable";
import "./DocumentViewer.css";
import fetchCrmReference from "../../services/fetchCRMReference";

const DocumentViewer = ({ deals }) => {
  const [refreshFlag, setRefreshFlag] = useState(false);
  const documentName = useSelector(
    (state) => state.activeComponent.documentName
  );
  const filePath = useSelector((state) => state.activeComponent.filePath);
  const franchiseName = useSelector((state) => state.franchise.franchiseName);
  const dealId = useSelector((state) => state.deal.dealId);
  const companyName = useSelector(
    (state) => state.selectedCompany.selectedCompany?.name
  );
  const [crmReference, setCrmReference] = useState("");
  const [isFetchingCrmReference, setIsFetchingCrmReference] = useState(false);

  useEffect(() => {
    const fetchReference = async () => {
      if (!documentName) return;

      try {
        setIsFetchingCrmReference(true);
        const reference = await fetchCrmReference(documentName);
        setCrmReference(reference);
      } catch (error) {
        console.error("Error fetching CRM reference:", error);
        setCrmReference("");
      } finally {
        setIsFetchingCrmReference(false);
      }
    };

    fetchReference();
  }, [documentName]);

  return (
    <div className="document-viewer">
      <DocumentActions
        documentName={documentName}
        filePath={filePath}
        deals={deals}
        crmReference={crmReference}
        franchiseName={franchiseName}
        onUploadSuccess={() => setRefreshFlag(!refreshFlag)}
      />
      <UploadedDocumentsTable
        dealId={dealId}
        franchiseName={franchiseName}
        companyName={companyName}
        documentName={documentName}
        refreshFlag={refreshFlag}
        crmReference={crmReference}
      />
    </div>
  );
};

export default DocumentViewer;

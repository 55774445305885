// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.howto-accordion {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  width: 100%;
  background: #f8f9fa;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:hover {
  background: #e9ecef;
}

.icon {
  font-size: 1.2rem;
}

.accordion-content {
  padding: 15px;
  background: #fff;
}

.file-entry {
  margin-bottom: 15px;
}

.preview-video {
  width: 100%;
  max-height: 400px;
  border-radius: 5px;
}

.preview-pdf {
  width: 100%;
  height: 500px;
  border: none;
}

.preview-image {
  min-width: 100%;
  height: auto;
}

/* Responsive */
@media (max-width: 600px) {
  .preview-pdf {
    height: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CompanyDashboard/HowToAccordion/HowToAccordion.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA,eAAe;AACf;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".howto-accordion {\r\n  width: 100%;\r\n  max-width: 800px;\r\n  margin: 20px auto;\r\n}\r\n\r\n.accordion-item {\r\n  border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.accordion-header {\r\n  width: 100%;\r\n  background: #f8f9fa;\r\n  padding: 12px;\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  border: none;\r\n  text-align: left;\r\n  cursor: pointer;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.accordion-header:hover {\r\n  background: #e9ecef;\r\n}\r\n\r\n.icon {\r\n  font-size: 1.2rem;\r\n}\r\n\r\n.accordion-content {\r\n  padding: 15px;\r\n  background: #fff;\r\n}\r\n\r\n.file-entry {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.preview-video {\r\n  width: 100%;\r\n  max-height: 400px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.preview-pdf {\r\n  width: 100%;\r\n  height: 500px;\r\n  border: none;\r\n}\r\n\r\n.preview-image {\r\n  min-width: 100%;\r\n  height: auto;\r\n}\r\n\r\n/* Responsive */\r\n@media (max-width: 600px) {\r\n  .preview-pdf {\r\n    height: 300px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "./FilePreviewModal.css";

const FilePreviewModal = ({ isOpen, fileUrl, fileType, onClose }) => {
  if (!isOpen) return null;

  let type = fileType ? fileType.toLowerCase() : "";

  if (!type) {
    const extension = fileUrl.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(extension)) {
      type = "image";
    } else if (["mp4", "mov", "avi", "webm", "ogg"].includes(extension)) {
      type = "video";
    } else if (["pdf"].includes(extension)) {
      type = "pdf";
    } else {
      type = "unknown";
    }
    console.warn("⚠ File type was missing, inferred as:", type);
  }

  return (
    <div className="file-preview-modal">
      <div className="file-preview-content">
        <button className="file-preview-close-button" onClick={onClose}>✖</button>
        {type === "image" && <img src={fileUrl} alt="Preview" className="preview-image" />}
        
        {type === "video" && (
          <video controls className="preview-video">
            <source src={fileUrl} type={`video/${fileUrl.split('.').pop()}`} />
            Your browser does not support the video tag.
          </video>
        )}
        
        {type === "pdf" && (
          <iframe src={fileUrl} title="PDF Preview" className="preview-pdf"></iframe>
        )}

        {type === "unknown" && (
          <p className="unsupported-message">
            Unsupported file format.{" "}
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              Click here to view the file
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default FilePreviewModal;

import axios from 'axios';

// Fetch deals by email from the backend
export const fetchDealsByEmail = async (email) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/deals?email=${email}`);
    // Check if response data is as expected
    if (!response.data || !Array.isArray(response.data)) {
      console.error('Unexpected response data format:', response.data);
      return [];
    }

    // console.log('Backend response: ', response);

    return response.data;
  } catch (error) {
    // Log detailed error information
    console.error('Error fetching deals:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Request setup error:', error.message);
    }
    
    throw error; // Re-throw to be handled in the component
  }
};

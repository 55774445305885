import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCompany: null,
  franchiseName: null,
  customData: {}, // To store all hc_ key/value pairs
  checklist: [],
  checklistLoading: false,
  selectedOrganization: null,
  loading: false,
  error: null,
};

const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
    setFranchiseName: (state, action) => {
      state.franchiseName = action.payload;
    },
    setCompanyCustomData: (state, action) => {
      state.customData = action.payload;
    },
    setChecklist: (state, action) => {
      state.checklist = action.payload;
    },
    clearChecklist: (state) => {
      state.checklist = [];
    },
    setChecklistLoading: (state, action) => {
      state.checklistLoading = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setSelectedCompany,
  clearSelectedCompany,
  setFranchiseName,
  setCompanyCustomData,
  setChecklist,
  clearChecklist,
  setChecklistLoading,
  setSelectedOrganization,
  setLoading,
  setError,
} = selectedCompanySlice.actions;

export default selectedCompanySlice.reducer;

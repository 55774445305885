// src/components/LoadingScreen/LoadingScreen.js
import React, { useState, useEffect } from "react";
import "./LoadingScreen.css";
import logo from "../../assets/images/Journey-white-home-site-logo.png"; // Update with your actual path

const LoadingScreen = ({ onLoadingComplete }) => { // Add a prop to handle completion
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(timer);
          setIsComplete(true); // Set to true once progress hits 100
          return 100;
        }
        return Math.min(oldProgress + 15, 100); // Faster increment
      });
    }, 200); // Faster interval

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Trigger the onLoadingComplete callback when isComplete becomes true
  useEffect(() => {
    if (isComplete) {
      onLoadingComplete(); // Call the navigation callback once loading is complete
    }
  }, [isComplete, onLoadingComplete]);

  return (
    <div className="loading-screen">
      <div className="logo-container">
        <img src={logo} alt="Loading Logo" className="loading-logo" />
        <div
          className="logo-overlay"
          style={{ clipPath: `inset(${100 - progress}% 0 0 0)` }}
        ></div>
      </div>
      <p className="loading-text">{progress}%</p>
    </div>
  );
};

export default LoadingScreen;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
} from "../../services/manageUsers";
import { fetchFranchisees } from "../../services/fetchFranchisees";
import UserEditModal from "./UserEditModal";
import { decryptEmail } from "../../services/decryptEmail";
import { encryptEmail } from "../../services/encryptEmail";
import "./UserManager.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaEdit, FaTrash } from "react-icons/fa";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [franchisees, setFranchisees] = useState([]);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    email: "",
    role: "",
    owning_team: "",
    validated: 0,
  });
  const [editingUser, setEditingUser] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);

  useEffect(() => {
    loadUsers();
    loadFranchisees();
  }, []);

  const loadUsers = async () => {
    try {
      const data = await fetchUsers();
      setUsers(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const loadFranchisees = async () => {
    try {
      const data = await fetchFranchisees();
      setFranchisees(data);
    } catch (error) {
      console.error("Failed to fetch franchisees:", error);
    }
  };

  const generatePassword = () => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    const password = Array(10)
      .fill()
      .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
      .join("");
    setNewUser({ ...newUser, password });
  };

  const handleCreateUser = async () => {
    if (
      !newUser.username.trim() ||
      !newUser.password.trim() ||
      !newUser.email.trim() ||
      !newUser.role ||
      !newUser.owning_team
    ) {
      toast.error("All fields must be completed.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    setIsCreatingUser(true);
    try {
      const encryptedNewUser = {
        ...newUser,
        email: encryptEmail(newUser.email),
        owning_team: franchisees.find(
          (franchise) => franchise.franchise_id === newUser.owning_team
        )?.franchise_id,
      };
      await createUser(encryptedNewUser);
      await loadUsers();
      setNewUser({
        username: "",
        password: "",
        email: "",
        role: "",
        owning_team: "",
        validated: 0,
      });
      toast.success("User added successfully!", {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Failed to create user:", error);
      toast.error("Failed to create user. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setIsCreatingUser(false); // Reset after process
    }
  };

  const handleUpdateUser = async () => {
    try {
      const updatedUser = {
        ...editingUser,
        email: encryptEmail(editingUser.email),
        owning_team: franchisees.find(
          (franchise) => franchise.franchise_id === editingUser.owning_team
        )?.franchise_id,
      };
      await updateUser(editingUser.id, updatedUser);
      await loadUsers();
      setEditingUser(null);
      setIsModalOpen(false);
      toast.success("User updated successfully!", {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Failed to update user:", error);
      toast.error("Failed to update user. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      setUsers(users.filter((user) => user?.id !== id));
      toast.success("User deleted successfully!", {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Failed to delete user:", error);
      toast.error("Failed to delete user. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const openEditModal = (user) => {
    setEditingUser({
      ...user,
      email: user.email ? decryptEmail(user.email) : "",
    });
    setIsModalOpen(true);
  };

  return (
    <div className="user-management">
      <h2>User Management</h2>

      <div className="add-user-form">
        <h3>Add New User</h3>
        <div className="form-row">
          <div className="left-column">
            <input
              type="text"
              placeholder="Username"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
              className="username-input"
            />
            <select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <option value="">Select Role</option>
              <option value="admin">Admin</option>
              <option value="account manager">Account Manager</option>
            </select>
          </div>
          <div className="middle-column">
            <input
              type="email"
              placeholder="Email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              className="user-email-input"
            />
            <select
              value={newUser.owning_team}
              onChange={(e) =>
                setNewUser({ ...newUser, owning_team: e.target.value })
              }
            >
              <option value="">Select Owning Team</option>
              {franchisees.map((franchise) => (
                <option
                  key={franchise.franchise_id}
                  value={franchise.franchise_id}
                >
                  {franchise.franchise_name}
                </option>
              ))}
            </select>
          </div>
          <div className="right-column">
            <div className="password-field">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={newUser.password}
                className="password-input"
                onChange={(e) =>
                  setNewUser({ ...newUser, password: e.target.value })
                }
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
              <button
                onClick={generatePassword}
                className="generate-password-button"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={handleCreateUser}
          disabled={isCreatingUser}
          className="add-user-button"
        >
          {isCreatingUser ? "Adding user..." : "Add User"}
        </button>
      </div>

      <div className="user-list">
        <h3>Current Users</h3>
        <table className="user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Owning Team</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user?.id}>
                <td>{user?.username ?? "N/A"}</td>
                <td>
                  {user?.email ? decryptEmail(user.email) : "Email unavailable"}
                </td>
                <td>{user?.role ?? "N/A"}</td>
                <td>
                  {franchisees.find(
                    (franchise) => franchise.franchise_id === user.owning_team
                  )?.franchise_name ?? "N/A"}
                </td>
                <td>
                  <button
                    className="edit-button"
                    onClick={() => openEditModal(user)}
                    aria-label="Edit User"
                  >
                    <FaEdit />
                  </button>
                </td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteUser(user.id)}
                    aria-label="Delete User"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UserEditModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        editingUser={editingUser}
        setEditingUser={setEditingUser}
        handleUpdateUser={handleUpdateUser}
        franchisees={franchisees}
      />
    </div>
  );
};

export default UserManagement;

import React, { useState, useEffect } from "react";
import "./ViewDocuments.css";
import DocumentModal from "../DocumentModal/DocumentModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";
import { toast } from "react-toastify";

const ViewDocuments = ({ documentType }) => {
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null); // Store the document ID to be deleted

  const apiUrl = process.env.REACT_APP_API_BASE_URL; // Get API URL from .env

  // Fetch documents from the backend based on documentType
  const fetchDocuments = async () => {
    if (!documentType || documentType === "Select document type") return;

    try {
      const response = await fetch(
        `${apiUrl}/documents?documentType=${documentType}`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setDocuments(data);
      } else {
        setDocuments([]); // In case the response is not an array
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]); // Set empty array on error
    }
  };

  // Handle viewing the document by opening a modal and setting the document URL
  const handleViewDocument = (document) => {
    if (!document || !document.file_path) {
      console.error("File path is missing for document:", document);
      toast.error("Document cannot be viewed. File path is missing.");
      return;
    }

    const documentUrl = `${apiUrl.replace('/api', '')}/uploads/${document.file_path}`;
    setSelectedDocumentUrl(documentUrl);
    setIsModalOpen(true); // Open the modal
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocumentUrl("");
  };

  // Open delete confirmation modal
  const openDeleteModal = (documentId) => {
    setDocumentToDelete(documentId); // Set the document to delete
    setIsDeleteModalOpen(true); // Open delete modal
  };

  // Handle closing the delete confirmation modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDocumentToDelete(null); // Reset the document to delete
  };

  // Handle deleting the document
  const confirmDeleteDocument = async () => {
    try {
      const response = await fetch(`${apiUrl}/documents/${documentToDelete}`, {
        method: "DELETE",
      });

      if (response.ok) {
        toast.success("Document deleted successfully!");
        fetchDocuments(); // Refresh the document list
      } else {
        toast.error("Error deleting document.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("Server error. Please try again later.");
    } finally {
      closeDeleteModal(); // Close the delete confirmation modal
    }
  };

  // Fetch documents whenever the documentType changes
  useEffect(() => {
    fetchDocuments();
  }, [documentType]);

  return (
    <div className="view-documents">
      <h3>View Documents</h3>
      <table className="documents-table">
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Last Updated</th>
            <th>View</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {documents.length > 0 ? (
            documents.map((doc, index) => (
              <tr key={index}>
                <td>{doc.document_name}</td>
                <td>{new Date(doc.updated_at).toLocaleDateString()}</td>
                <td
                  className="table-icon"
                  onClick={() => handleViewDocument(doc)}
                >
                  <span className="material-symbols-outlined">visibility</span>
                </td>
                <td
                  className="table-icon"
                  onClick={() => openDeleteModal(doc.document_id)}
                >
                  <span className="material-symbols-outlined">delete</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
                No documents available for this category.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for viewing the document */}
      {selectedDocumentUrl && (
        <DocumentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          documentUrl={selectedDocumentUrl}
        />
      )}

      {/* Modal for confirming document deletion */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDeleteDocument}
      />
    </div>
  );
};

export default ViewDocuments;

import axios from 'axios';

/**
 * Fetch all document names and CRM references from the server.
 * 
 * @param {Array<string>} crmReferences - List of CRM references to filter documents.
 * @returns {Promise<Array>} - A promise that resolves to an array of documents.
 */
const fetchDocumentNames = async (crmReferences = []) => {
  try {
    if (!Array.isArray(crmReferences) || crmReferences.length === 0) {
      console.warn("CRM references must be a non-empty array.");
      return [];
    }

    // Construct query parameters
    const queryParams = new URLSearchParams({
      crmReferences: crmReferences.join(","), // Pass CRM references if provided
    }).toString();

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/documents/fetch-document-names?${queryParams}`;
    console.log("Making API call to:", apiUrl);

    // Make the API call to fetch all documents
    const response = await axios.get(apiUrl);

    // Validate and return the data
    if (response.status === 200 && Array.isArray(response.data)) {
      console.log("Fetched documents:", response.data);
      return response.data;
    } else {
      console.warn("Unexpected response structure:", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching document names:", error);
    return [];
  }
};

export default fetchDocumentNames;

import axios from "axios";

/**
 * Function to find a document by name and optionally delete it.
 *
 * @param {string} locationName - The name of the folder/location.
 * @param {string} companyName - The name of the company.
 * @param {string} fileName - The name of the file to search for.
 * @param {string} [dealId] - The optional deal ID if applicable.
 * @returns {Promise} - Resolves with document details if found, otherwise rejects with an error.
 */
const deleteUploadedDocument = async (locationName, companyName, fileName, dealId) => {
  try {
    const requestBody = {
      locationName,
      companyName,
      fileName,
      dealId,
    };

    // Log the outgoing request body
    console.log("Request Body Sent to Backend:", requestBody);

    // Make a POST request to the backend endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL || "http://localhost:5000"}/sharepoint-documents/find-document`,
      requestBody
    );

    if (response.status === 200) {
      console.log("Document found:", response.data);
      return response.data;
    } else {
      throw new Error("Failed to find the document.");
    }
  } catch (error) {
    console.error("Error finding the document:", error.message);
    throw error;
  }
};

export default deleteUploadedDocument;

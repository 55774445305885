import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainContent from "../../components/MainContent/MainContent";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedCompany,
  clearSelectedCompany,
} from "../../redux/slices/selectedCompanySlice";
import { setDealId } from "../../redux/slices/dealSlice";
import { setSidebarData } from "../../redux/slices/sidebarSlice"; // Import action for Redux
import { loginSuccess } from "../../redux/slices/authSlice";
import { fetchDealsByEmail } from "../../services/fetchDealsService";
import { fetchTeamDeals } from "../../services/fetchTeamDeals";
import { extractFieldsAndValues } from "../../services/extractDealData";
import "./Dashboard.css";

const Dashboard = () => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [requiredReferences, setRequiredReferences] = useState([]);
  const [receivedReferences, setReceivedReferences] = useState([]);

  // Redux state
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const selectedCompany = useSelector(
    (state) => state.selectedCompany.selectedCompany
  );
  const dealId = useSelector((state) => state.deal.dealId);

  const dispatch = useDispatch();

  // Fetch deals on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;

        // Handle user role assignment
        if (!user?.role && user?.email) {
          const updatedUser = { ...user, role: "default_role" };
          dispatch(loginSuccess({ token: null, user: updatedUser }));
        }

        // Fetch deals based on user role
        if (
          (user?.role === "account manager" || user?.role === "admin") &&
          user?.owning_team
        ) {
          // Admins and Account Managers fetch deals for their team
          data = await fetchTeamDeals(user.owning_team);
        } else if (user?.email) {
          // All other roles fetch deals by email
          data = await fetchDealsByEmail(user.email);
        }

        if (data) {
          const dealDetails = data.map((deal) => ({
            id: deal.new_dealid,
            name: deal.new_name || "No Name",
            total_employees: deal.hc_employeecount,
            stateId: deal.hc_operatingstate,
            franchiseId: deal._hc_franchise_team_value,
            raw: deal,
          }));
          setDeals(dealDetails);

          if (dealDetails[0]?.stateId) {
            setStateId(dealDetails[0].stateId);
          }
        }
      } catch (error) {
        setError("Failed to fetch deals.");
        console.error("Error fetching deals:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, dispatch]);

  // Extract references and assign to Redux
  const initializeData = () => {
    if (!deals || deals.length === 0 || !selectedCompany) {
      console.warn("No deals or selected company to process.");
      return;
    }

    const extractedData = extractFieldsAndValues(deals, selectedCompany);
    const required = new Set();
    const received = new Set();

    extractedData.forEach((item, index) => {
      if (typeof item !== "object" || !item) {
        console.warn(`Item at index ${index} is not valid: `, item);
        return;
      }

      Object.entries(item).forEach(([key, value]) => {
        if (value === 122990101) {
          required.add(key); // Add to required Set
        } else if (value === 122990103) {
          received.add(key); // Add to received Set
        }
      });
    });

    // Convert Sets to arrays
    const requiredArray = Array.from(required);
    const receivedArray = Array.from(received);

    // Update references in state and Redux
    setRequiredReferences(requiredArray);
    setReceivedReferences(receivedArray);

    dispatch(
      setSidebarData({
        requiredReferences: requiredArray,
        receivedReferences: receivedArray,
      })
    );
  };

  useEffect(() => {
    if (selectedCompany) {
      initializeData();
    }
  }, [selectedCompany, deals]);

  const handleCompanyChange = (company) => {
    dispatch(setSelectedCompany(company));

    if (!company) {
      setStateId(null);
      dispatch(clearSelectedCompany());
      dispatch(setDealId(null));
      setRequiredReferences([]);
      setReceivedReferences([]);
      dispatch(
        setSidebarData({ requiredReferences: [], receivedReferences: [] })
      );
      return;
    }

    setStateId(company.stateId);
    dispatch(setDealId(company.id));
  };

  const clearSearch = () => {
    setSearchTerm("");
    setShowDropdown(false);
    handleCompanyChange(null);
  };

  const handleLoadingComplete = () => {
    console.log("Loading complete.");
  };

  // Always render the dashboard once loading is complete
  if (loading) {
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} />;
  }

  if (error) {
    console.error("Error detected:", error);
  }

  return (
    <div className="dashboard">
      <Navbar />
      <div className="dashboard-container">
        <Sidebar
          stateId={stateId}
          franchiseId={selectedCompany?.franchiseId ?? null}
          dealId={dealId} // Pass dealId to Sidebar
          requiredReferences={requiredReferences}
          receivedReferences={receivedReferences}
        />
        <MainContent
          deals={deals}
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange}
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          stateId={stateId}
          setStateId={setStateId}
          requiredReferences={requiredReferences}
          receivedReferences={receivedReferences}
          progressPercentage={
            requiredReferences.length + receivedReferences.length > 0
              ? (receivedReferences.length /
                  (requiredReferences.length + receivedReferences.length)) * 100
              : 0
          }
        />
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import "./Navbar.css";
import logo from "../../assets/images/Journey-white-home-site-logo.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Dispatch the logout action to update the Redux state
    dispatch(logout());

    // Clear any session data you may be using for authentication
    sessionStorage.clear();

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <div className="navbar-wrapper">
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
      <div className="navbar-rest">
        <div className="icons-container">
          <span className="material-symbols-outlined">help</span>
          <span className="material-symbols-outlined" onClick={handleLogout}>
            logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

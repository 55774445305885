import axios from 'axios';
const baseURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

// Fetch state by code
const fetchStateByCode = async (stateCode) => {
  try {
    const response = await axios.get(`${baseURL}/api/states/by-code?code=${stateCode}`);
    return response.data?.state_abbreviation; // Assuming state abbreviation is returned
  } catch (error) {
    console.error('Error fetching state by code:', error.response?.data?.message || error.message);
    throw error;
  }
};

export default fetchStateByCode; // Default export

// src/pages/ResetPassword.js
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validateResetToken from "../../services/validateResetToken";
import "react-toastify/dist/ReactToastify.css";
import "./ResetPassword.css";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(8);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#-])[A-Za-z\d@$!%*?&#-]{8,}$/;

  // Validate the token on page load
  useEffect(() => {
    const checkToken = async () => {
      const isValid = await validateResetToken(token, navigate);
      if (!isValid) {
        navigate("/login");
      }
    };
    if (token) {
      checkToken();
    } else {
      toast.error("No token provided. Redirecting to login.");
      navigate("/login");
    }
  }, [navigate, token]);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const validatePassword = () => {
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.",
        { position: "top-center", autoClose: 3000 }
      );
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 3000,
      });
      return false;
    }
    if (!passwordChanged) {
      toast.error("You must change the password before resetting.", {
        position: "top-center",
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPasswordChanged(password && password === confirmPassword);
  }, [password, confirmPassword]);

  const generateRandomPassword = () => {
    const length = Math.max(passwordLength, 8);
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&#-";
    let randomPassword = "";
    for (let i = 0, n = charset.length; i < length; i++) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setPassword(randomPassword);
    setConfirmPassword(randomPassword);
  };

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setConfirmPasswordVisible(!confirmPasswordVisible);

  const handleResetPassword = async () => {
    if (!validatePassword()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
        { password, token }// Ensure `newPassword` is used if that's what the backend expects
      );

      if (response.status === 200) {
        toast.success("Password reset successful!", {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/login");
      }
    } catch (error) {
      toast.error("Error resetting password. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-page-container">
      <div className="reset-password-logo-container">{/* Optional logo */}</div>
      <div className="reset-password-page-form">
        <h2 className="reset-password-page-title">Reset Password</h2>
        <div className="password-input-container">
          <input
            className="reset-password-page-input"
            type={passwordVisible ? "text" : "password"}
            placeholder="New Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <span onClick={togglePasswordVisibility} className="visibility-icon">
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="password-input-container">
          <input
            className="reset-password-page-input"
            type={confirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <span
            onClick={toggleConfirmPasswordVisibility}
            className="visibility-icon"
          >
            {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="password-options">
          <label>Password Length: </label>
          <input
            className="password-length-input"
            type="number"
            min="8"
            value={passwordLength}
            onChange={(e) => setPasswordLength(e.target.value)}
          />
          <button
            className="generate-password-button"
            onClick={generateRandomPassword}
          >
            Generate Password
          </button>
        </div>
        <button
          className="reset-password-page-button"
          onClick={handleResetPassword}
          disabled={loading || !passwordChanged}
        >
          {loading ? "Resetting..." : "Reset Password"}
        </button>
        <p className="back-to-login-link" onClick={() => navigate("/login")}>
          Back to Login
        </p>
      </div>
      {loading && <div className="loading-message">Please wait...</div>}
    </div>
  );
};

export default ResetPassword;

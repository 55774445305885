import React from "react";
import ReactDOM from "react-dom";
import "./DocumentModal.css";

const DocumentModal = ({ isOpen, onClose, documentUrl }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <object
          data={documentUrl}
          type="application/pdf"
          className="pdf-viewer"
        >
          <p>
            Your browser does not support PDFs.{" "}
            <a href={documentUrl}>Download the PDF</a> instead.
          </p>
        </object>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default DocumentModal;

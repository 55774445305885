import axios from 'axios';

/**
 * Fetch all document names and CRM references from the server.
 * 
 * @param {Array<string>} crmReferences - List of CRM references to filter documents.
 * @returns {Promise<Array>} - A promise that resolves to an array of documents.
 */
const fetchDocumentNames = async (crmReferences = []) => {
  try {
    if (!Array.isArray(crmReferences) || crmReferences.length === 0) {
      console.warn("CRM references must be a non-empty array.");
      return [];
    }

    // Construct query parameters
    const queryParams = new URLSearchParams({
      crmReferences: crmReferences.join(","), // Pass CRM references if provided
    }).toString();

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/documents/fetch-document-names?${queryParams}`;
    const response = await axios.get(apiUrl);

    if (response.status === 200 && Array.isArray(response.data)) {
      
      // Ensure we return only relevant fields including file_path
      return response.data.map(doc => ({
        document_id: doc.document_id,
        document_name: doc.document_name,
        description: doc.description,
        purpose: doc.purpose,
        company_id: doc.company_id,
        franchise_id: doc.franchise_id,
        state_id: doc.state_id,
        crm_reference: doc.crm_reference,
        file_path: doc.file_path
          ? encodeURI(`${process.env.REACT_APP_API_BASE_URL}/uploads/${doc.file_path}`)
          : null, // ✅ Construct full file path
        created_at: doc.created_at,
        updated_at: doc.updated_at
      }));
    } else {
      console.warn("Unexpected response structure:", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching document names:", error);
    return [];
  }
};

export default fetchDocumentNames;

// activeComponentSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const activeComponentSlice = createSlice({
  name: 'activeComponent',
  initialState: {
    activeComponent: 'companyDashboard',  // Default component
    documentName: '',  // Default document name (empty)
    filePath: '',  // Default file path (empty)
  },
  reducers: {
    setActiveComponent: (state, action) => {
      state.activeComponent = action.payload.component;
      state.documentName = action.payload.documentName || '';
      state.filePath = action.payload.filePath || ''; // Capture filePath in Redux state
    },
  },
});

export const { setActiveComponent } = activeComponentSlice.actions;
export default activeComponentSlice.reducer;

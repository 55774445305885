// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #193768;
    color: #fff;
    height: 60px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .logo-container {
    width: 250px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 100%;
  }
  
  .logo-container img {
    max-height: 40px;
  }
  
  .navbar-rest {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    height: 100%;
  }
  
  .icons-container {
    display: flex;
    gap: 1rem;
  }
  
  .icons-container .material-symbols-outlined {
    font-size: 24px;
    cursor: pointer;
  }
  
  .icons-container .material-symbols-outlined:hover {
    color: #ddd;
  }
  
  .logout-container{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,wCAAwC;EAC1C;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,SAAO;IACP,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;EACjB","sourcesContent":[".navbar-wrapper {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px;\r\n    background-color: #193768;\r\n    color: #fff;\r\n    height: 60px;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);\r\n  }\r\n  \r\n  .logo-container {\r\n    width: 250px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding-left: 1rem;\r\n    height: 100%;\r\n  }\r\n  \r\n  .logo-container img {\r\n    max-height: 40px;\r\n  }\r\n  \r\n  .navbar-rest {\r\n    flex: 1;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n    padding-right: 1rem;\r\n    height: 100%;\r\n  }\r\n  \r\n  .icons-container {\r\n    display: flex;\r\n    gap: 1rem;\r\n  }\r\n  \r\n  .icons-container .material-symbols-outlined {\r\n    font-size: 24px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .icons-container .material-symbols-outlined:hover {\r\n    color: #ddd;\r\n  }\r\n  \r\n  .logout-container{\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 10px;\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

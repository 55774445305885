// src/redux/slices/organizationSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    selectedOrganization: '',
  },
  reducers: {
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
});

export const { setSelectedOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;

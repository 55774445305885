import React, { useEffect, useState, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import MagicLink from "./pages/MagicLink/MagicLink";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NotFound from "./pages/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateViaMagicLink,
  authenticateViaMagicLinkFailure,
} from "./redux/slices/authSlice";
import { validateMagicLinkToken } from "./services/validateTokenService";
import validateResetToken from "./services/validateResetToken";
import { fetchContactByEmail } from "./services/fetchContactByEmailService";
import validateAccountToken from "./services/validateAccountToken";

function App() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidatingToken, setIsValidatingToken] = useState(true);
  const validationRun = useRef(false); // Ref to ensure validation runs only once

  useEffect(() => {
    // Prevent multiple executions
    if (validationRun.current) return;
    validationRun.current = true;

    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    const path = location.pathname;
    const storedMagicToken = sessionStorage.getItem("magicToken");

    const validateMagicTokenAndFetchContact = async (token) => {
      try {
        const responseData = await validateMagicLinkToken(token);
        dispatch(authenticateViaMagicLink(responseData)); // Authenticate user on success
        sessionStorage.setItem("email", responseData.email); // Store validated email

        // Fetch contact ID (validates that user exists in CRM)
        await fetchContactByEmail(responseData.email);
        navigate("/dashboard"); // Redirect to dashboard after successful validation
      } catch (error) {
        console.error("Magic link validation failed:", error.message);
        dispatch(authenticateViaMagicLinkFailure("Invalid or expired token"));
        sessionStorage.removeItem("magicToken");
        navigate("/login"); // Redirect to login on validation failure
      } finally {
        setIsValidatingToken(false); // Finish validation process
      }
    };

    const validateResetTokenAndNavigate = async (token) => {
      try {
        const isValid = await validateResetToken(token, navigate);
        if (!isValid) {
          navigate("/login");
        }
      } catch (error) {
        console.error("Reset password validation failed:", error.message);
        navigate("/login");
      } finally {
        setIsValidatingToken(false);
      }
    };

    const validateUserAccountAndNavigate = async (token) => {

      try {
        const responseData = await validateAccountToken(token);

        if (responseData && responseData.isValid) {
          dispatch(authenticateViaMagicLink({ email: responseData.email }));
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Account verification failed:", error.message);
        navigate("/login");
      } finally {
        setIsValidatingToken(false);
      }
    };

    const runValidation = async () => {
      if (path.includes("/dashboard") && tokenFromUrl && !storedMagicToken) {
        sessionStorage.setItem("magicToken", tokenFromUrl);
        await validateMagicTokenAndFetchContact(tokenFromUrl);
      } else if (path.includes("/reset-password") && tokenFromUrl) {
        await validateResetTokenAndNavigate(tokenFromUrl);
      } else if (path.includes("/profile/verify") && tokenFromUrl) {
        await validateUserAccountAndNavigate(tokenFromUrl);
      } else if (storedMagicToken && !isAuthenticated) {
        await validateMagicTokenAndFetchContact(storedMagicToken);
      } else {
        setIsValidatingToken(false); // End loading if no conditions are met
      }
    };

    runValidation();
  }, [dispatch, location, navigate, isAuthenticated]);

  const requireAuth = (element) => {
    if (isValidatingToken) {
      return;
    }
    if (!isAuthenticated) {
      return <Navigate to="/login" />; // Redirect to login if not authenticated
    }
    return element;
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/dashboard" element={requireAuth(<Dashboard />)} />
        <Route path="/login" element={<Login />} />
        <Route path="/magic-link" element={<MagicLink />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;

// src/services/fetchContactByEmailService.js
import axios from 'axios';

// Fetch contact ID by email from the backend
export const fetchContactByEmail = async (email) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contacts?email=${email}`);
    return response.data.contactId; // Assuming the response returns the contactId
  } catch (error) {
    console.error('Error fetching contact ID:', error);
    throw error; // Handle this in the component
  }
};

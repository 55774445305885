import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Page Not Found</p>
      <button className="not-found-button" onClick={handleGoHome}>
        Go to Login
      </button>
    </div>
  );
};

export default NotFound;

// src/utils/validateResetToken.js
import axios from "axios";
import { toast } from "react-toastify";

const validateResetToken = async (token, navigate) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/validate-reset-token`,
      { token }
    );
    if (response.status === 200) {
      return true;
    } else {
      toast.error("Invalid or expired token.");
      navigate("/login");
      return false;
    }
  } catch (error) {
    toast.error("Token validation failed. Please try again.");
    navigate("/login");
    return false;
  }
};

export default validateResetToken;

import React, { useRef } from "react";
import "./CompanySelector.css";

const CompanySelector = ({
  deals,
  selectedCompany,
  searchTerm,
  setSearchTerm,
  handleCompanyChange,
  showDropdown,
  setShowDropdown,
  stateAbbreviation,
  franchiseName,
  clearSearch,
}) => {
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  const handleInputClick = () => {
    if (deals.length > 0) {
      setShowDropdown(true);
    }
  };

  const handleDealChange = (deal) => {
    if (!deal) {
      handleCompanyChange(null);
      return;
    }

    handleCompanyChange(deal);
    setSearchTerm(deal.name);
    setShowDropdown(false);
  };

  return (
    <div className="company-selector">
      <label htmlFor="deal-select">Search By Name:</label>
      <div className="search-container">
        <input
          ref={inputRef}
          type="text"
          id="company-search"
          placeholder={
            deals.length === 1 ? deals[0].name : "Search company..."
          }
          value={searchTerm}
          onChange={handleSearchChange}
          onClick={handleInputClick}
          disabled={deals.length === 1}
          autoComplete="off"
        />
        {searchTerm ? (
          <span
            className="material-symbols-outlined clear-icon"
            onClick={clearSearch}
          >
            close
          </span>
        ) : (
          <span className="material-symbols-outlined search-icon">
            search
          </span>
        )}
      </div>
      {showDropdown && deals.length > 1 && (
        <ul className="dropdown" ref={dropdownRef}>
          {deals
            .filter((deal) =>
              deal.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((deal) => (
              <li
                key={deal.id}
                className="dropdown-item"
                onClick={() => handleDealChange(deal)}
              >
                {deal.name}
              </li>
            ))}
          {deals.filter((deal) =>
            deal.name.toLowerCase().includes(searchTerm.toLowerCase())
          ).length === 0 && (
            <li className="dropdown-item no-match">No deals found</li>
          )}
        </ul>
      )}
      <div className="company-info">
        <h3>{selectedCompany?.name || "No Company Selected"}</h3>
        <p>
          <strong>State:</strong> {stateAbbreviation || "N/A"}
        </p>
        {/* <p>
          <strong>Franchise:</strong> {franchiseName || "N/A"}
        </p> */}
        <p>
          <strong>Total Employees:</strong>{" "}
          {selectedCompany?.total_employees || "N/A"}
        </p>
      </div>
    </div>
  );
};

export default CompanySelector;

import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  setFranchiseName,
  setLoading,
  setError,
} from "../../redux/slices/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOrganization } from "../../redux/slices/organizationSlice";
import fetchFranchiseName from "../../services/fetchFranchiseName";
import fetchStateByCode from "../../services/fetchStateByCode";
import fetchSidebarData from "../../services/fetchSidebarData"; // Importing the script
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DocumentModal from "../DocumentModal/DocumentModal";
import "./CompanyDashboard.css";
import {
  setChecklist,
  clearChecklist,
  setChecklistLoading,
  selectChecklist,
  selectChecklistLoading,
} from "../../redux/slices/checklistSlice";
import Checklist from "./Checklist/Checklist";
import CompanySelector from "./CompanySelector/CompanySelector";

const CompanyDashboard = ({
  deals = [],
  selectedCompany,
  handleCompanyChange,
  clearSearch,
  searchTerm,
  setSearchTerm,
  showDropdown,
  setShowDropdown,
  setStateId,
  progressPercentage,
  requiredReferences = [],
  receivedReferences = [],
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateAbbreviation, setStateAbbreviation] = useState("");
  const documentData = useSelector(selectChecklist);
  const isLoadingDocuments = useSelector(selectChecklistLoading);
  const franchiseName = useSelector((state) => state.franchise.franchiseName);

  // Dynamically compute combined references
  const combinedReferences = useMemo(() => {
    const validRequired = Array.isArray(requiredReferences)
      ? requiredReferences
      : [];
    const validReceived = Array.isArray(receivedReferences)
      ? receivedReferences
      : [];
    return [...new Set([...validRequired, ...validReceived])];
  }, [requiredReferences, receivedReferences]);

  // Consolidated useEffect
  useEffect(() => {
    const initializeCompanyData = async () => {
      // Handle auto-selection of a single deal
      if (deals.length === 1 && !selectedCompany) {
        handleCompanyChange(deals[0]);
        return;
      }

      if (!selectedCompany) {
        dispatch(setFranchiseName(null));
        setStateAbbreviation("");
        dispatch(clearChecklist());
        return;
      }

      // Reset checklist and set loading
      dispatch(clearChecklist());
      dispatch(setChecklistLoading(true));
      dispatch(setSelectedOrganization(selectedCompany.name));

      try {
        // Fetch franchise name
        if (selectedCompany.franchiseId) {
          dispatch(setLoading(true));
          const franchiseName = await fetchFranchiseName(
            selectedCompany.franchiseId
          );
          dispatch(setFranchiseName(franchiseName || "N/A"));
        } else {
          dispatch(setFranchiseName("N/A"));
        }

        // Fetch state abbreviation
        const stateId = selectedCompany.raw?.hc_operatingstate;
        if (stateId) {
          setStateId(stateId);
          const stateAbbreviation = await fetchStateByCode(stateId);
          setStateAbbreviation(
            Array.isArray(stateAbbreviation)
              ? stateAbbreviation.join(", ")
              : stateAbbreviation || "N/A"
          );
        } else {
          setStateAbbreviation("N/A");
        }

        // Fetch document checklist
        if (combinedReferences.length > 0) {
          const sections = await fetchSidebarData(
            selectedCompany.franchiseId,
            selectedCompany.raw?.hc_operatingstate,
            combinedReferences
          );

          const sectionItems = Object.values(sections || {}).flatMap(
            (section) => section.items || []
          );

          const mappedDocuments = combinedReferences.map((reference) => {
            const matchingDocument = sectionItems.find(
              (doc) => doc.crmReference === reference
            );

            return {
              documentName: matchingDocument?.name || reference,
              crmReference: reference,
              status: receivedReferences.includes(reference)
                ? "Uploaded"
                : "Incomplete",
              filePath: matchingDocument?.filePath || null,
              uploadedDate: matchingDocument?.uploadedDate || null,
            };
          });
          dispatch(setChecklist(mappedDocuments));
        } else {
          console.warn("No combinedReferences available. Skipping fetch.");
          dispatch(clearChecklist());
        }
      } catch (error) {
        console.error("Error initializing company data:", error);
        dispatch(setError("Error fetching company data"));
      } finally {
        dispatch(setChecklistLoading(false));
        dispatch(setLoading(false));
      }
    };

    initializeCompanyData();
  }, [
    deals,
    selectedCompany,
    combinedReferences,
    handleCompanyChange,
    dispatch,
    setStateId,
    receivedReferences,
  ]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close the dropdown
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const lastUploadedDocumentUrl = "/UploadedDocuments/W4-Employee.pdf";

  return (
    <div className="company-dashboard">
      <div className="top-container">
        <CompanySelector
          deals={deals}
          selectedCompany={selectedCompany}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleCompanyChange={handleCompanyChange}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          stateAbbreviation={stateAbbreviation}
          franchiseName={franchiseName}
          clearSearch={clearSearch}
        />
        <div className="progress-card">
          <h3 className="progress-card-heading">Document Upload Progress</h3>
          <CircularProgressbar
            value={progressPercentage}
            text={`${Math.round(progressPercentage)}%`}
            styles={buildStyles({
              textColor: "#333",
              pathColor: progressPercentage === 100 ? "#4c8c40" : "#4c8c40",
              trailColor: "#d6d6d6",
            })}
          />
        </div>

        <div className="checklist-container">
          {selectedCompany ? (
            <Checklist
              selectedCompany={selectedCompany}
              combinedReferences={combinedReferences}
              receivedReferences={receivedReferences}
            />
          ) : (
            <div className="no-company-selected">
              <p>Please select a company to view its dashboard.</p>
            </div>
          )}
        </div>
      </div>
      {selectedCompany && (
        <DocumentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          documentUrl={lastUploadedDocumentUrl}
        />
      )}
    </div>
  );
};

export default CompanyDashboard;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checklist: [], // Array to store the checklist items
  isLoading: false, // Loading state for the checklist
  error: null, // Error message, if any
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    setChecklist: (state, action) => {
      // Replace the checklist with new data
      state.checklist = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    clearChecklist: (state) => {
      // Clear the checklist
      state.checklist = [];
      state.isLoading = false;
      state.error = null;
    },
    setChecklistLoading: (state, action) => {
      // Set loading state
      state.isLoading = action.payload;
    },
    setChecklistError: (state, action) => {
      // Set error state
      state.error = action.payload;
      state.isLoading = false;
    },
    updateChecklistItem: (state, action) => {
      // Update a specific item in the checklist by CRM reference
      const { crmReference, updates } = action.payload;
      const itemIndex = state.checklist.findIndex(
        (item) => item.crmReference === crmReference
      );
      if (itemIndex !== -1) {
        state.checklist[itemIndex] = {
          ...state.checklist[itemIndex],
          ...updates,
        };
      }
    },
  },
});

export const {
  setChecklist,
  clearChecklist,
  setChecklistLoading,
  setChecklistError,
  updateChecklistItem,
} = checklistSlice.actions;

export const selectChecklist = (state) => state.checklist.checklist; // Selector for checklist
export const selectChecklistLoading = (state) => state.checklist.isLoading; // Selector for loading state
export const selectChecklistError = (state) => state.checklist.error; // Selector for error state

export default checklistSlice.reducer;

import { store } from "../redux/store"; // Access Redux state
import fetchStateDocuments from "./fetchStateDocuments"; // Ensure this is a valid import
import fetchFranchiseDocuments from "./fetchFranchiseDocuments"; // Ensure this is a valid import
import fetchGeneralDocuments from "./fetchGeneralDocuments"; // Ensure this is a valid import
import fetchAdditionalDocuments from "./fetchAdditionalDocuments"; // Ensure this is a valid import

const fetchSidebarData = async (franchiseId, stateId) => {
  try {
    // Retrieve CRM references from Redux
    const { requiredReferences = [], receivedReferences = [] } =
      store.getState().sidebar.data;

    // Combine required and received references
    const crmReferences = [...requiredReferences, ...receivedReferences];

    // Guard clause to prevent execution if crmReferences are empty
    if (!Array.isArray(crmReferences) || crmReferences.length === 0) {
      console.warn("crmReferences are empty. Skipping fetch.");
      return {}; // Exit early if crmReferences are empty
    }

    // Fetch documents concurrently
    const [
      stateDocuments,
      franchiseDocuments,
      generalDocuments,
      additionalDocuments,
    ] = await Promise.all([
      fetchStateDocuments(stateId, crmReferences),
      fetchFranchiseDocuments(franchiseId, crmReferences),
      fetchGeneralDocuments(crmReferences),
      fetchAdditionalDocuments(crmReferences),
    ]);

    // Combine all fetched documents
    const combinedDocuments = [
      ...stateDocuments,
      ...franchiseDocuments,
      ...generalDocuments,
      ...additionalDocuments,
    ];

    // Group documents into sections
    const sections = combinedDocuments.reduce((acc, doc) => {
      const section = doc.section || "General";
      if (!acc[section]) acc[section] = { items: [] };
      const documentUrl = doc.file_path
        ? encodeURI(`${process.env.REACT_APP_API_BASE_URL}/uploads/${doc.file_path}`)
        : null;
      acc[section].items.push({
        name: doc.document_name,
        documentId: doc.document_id,
        crmReference: doc.crmReferences,
        filePath: documentUrl,
      });
      return acc;
    }, {});

    return sections;
  } catch (error) {
    console.error("Error fetching sidebar data:", error);
    return {};
  }
};

export default fetchSidebarData;

export const extractFieldsAndValues = (deals, selectedCompany) => {
    if (!deals || deals.length === 0 || !selectedCompany) return [];
  
    const filteredData = deals.filter((deal) => deal.id === selectedCompany.id);
  
    if (filteredData.length === 0) {
      console.warn("No matching deals found for the selected company.");
      return [];
    }
  
    const extractedData = filteredData.map((deal) => ({
      id: deal.id,
      name: deal.name,
      hc_clientchecklist: deal.raw?.hc_clientchecklist || "N/A",
      hc_doc_8655: deal.raw?.hc_doc_8655 || "N/A",
      hc_businessownersssn: deal.raw?.hc_businessownersssn || "N/A",
      hc_businessbankinginformation: deal.raw?.hc_businessbankinginformation || "N/A",
      hc_coloradostatenumberapplicationform:
        deal.raw?.hc_coloradostatenumberapplicationform || "N/A",
      hc_currentavailablebalancesforeachactiveemploye:
        deal.raw?.hc_currentavailablebalancesforeachactiveemploye || "N/A",
      hc_copyofbusinessownersdriverslicense:
        deal.raw?.hc_copyofbusinessownersdriverslicense || "N/A",
      hc_einletterfromirsorprevioussigned941formein:
        deal.raw?.hc_einletterfromirsorprevioussigned941formein || "N/A",
      hc_localtaxnumbers: deal.raw?.hc_localtaxnumbers || "N/A",
      hc_natpayachauthorizationform: deal.raw?.hc_natpayachauthorizationform || "N/A",
      hc_natpaybeneficialowneraddendum:
        deal.raw?.hc_natpaybeneficialowneraddendum || "N/A",
      hc_payrollserviceneedstimeline: deal.raw?.hc_payrollserviceneedstimeline || "N/A",
      hc_planassignmentforeachactiveemployee:
        deal.raw?.hc_planassignmentforeachactiveemployee || "N/A",
      hc_serviceagreement: deal.raw?.hc_serviceagreement || "N/A",
      hc_signaturereportingform: deal.raw?.hc_signaturereportingform || "N/A",
      hc_statedisability: deal.raw?.hc_statedisability || "N/A",
      hc_stateunemploymentpoa: deal.raw?.hc_stateunemploymentpoa || "N/A",
      hc_statewithholdingpoa: deal.raw?.hc_statewithholdingpoa || "N/A",
      hc_timeoffaccrualplansetupform: deal.raw?.hc_timeoffaccrualplansetupform || "N/A",
      hc_unemployment: deal.raw?.hc_unemployment || "N/A",
      hc_withholding: deal.raw?.hc_withholding || "N/A",
      hc_grosstonetbreakdownpecomptotalforeachq:
        deal.raw?.hc_grosstonetbreakdownpecomptotalforeachq || "N/A",
      hc_grosstonetbreakdownpeincycomptotalepp:
        deal.raw?.hc_grosstonetbreakdownpeincycomptotalepp || "N/A",
      hc_all941forms: deal.raw?.hc_all941forms || "N/A",
      hc_stateuitrforms: deal.raw?.hc_stateuitrforms || "N/A",
      hc_alltaxpaymentsmadeforcurrentyear:
        deal.raw?.hc_alltaxpaymentsmadeforcurrentyear || "N/A",
      hc_providemidquartertaxdeposits:
        deal.raw?.hc_providemidquartertaxdeposits || "N/A",
    }));
  
    // console.log("Extracted data for CRM references:", extractedData);
    return extractedData;
  };
  
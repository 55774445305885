import axios from "axios";

const createAcknowledgement = async (hashed_email) => {
    const payload = { hashed_email };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/acknowledgements/create`,
            payload,
            { headers: { "Content-Type": "application/json" } }
        );
        return response.data;
    } catch (error) {
        console.error("Error creating acknowledgement:", error);
        throw error;
    }
};

export default createAcknowledgement;

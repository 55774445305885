import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import "./ESignModal.css";
import { prepopulatePdf, logPdfFormFields } from "../../utils/pdfUtils";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import updateDocumentStatus from "../../services/updateDocumentStatus";
import fetchSignature from "../../services/fetchSignature";
import { PDFDocument } from "pdf-lib";

const ESignModal = ({
  isOpen,
  onClose,
  documentUrl,
  dealId,
  crmReference,
  documentName,
}) => {
  const [updatedPdfUrl, setUpdatedPdfUrl] = useState(null);
  const [fetchedSignature, setFetchedSignature] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const franchiseName = useSelector((state) => state.franchise.franchiseName);
  console.log('Fetched Document URL: ', documentUrl) // This URL is incorrect
  

  const companyCustomData = useSelector(
    (state) => state.selectedCompany.selectedCompany?.raw || {}
  );

  const prepopulateData = useMemo(
    () => ({
      Hc_address: companyCustomData.hc_address || "",
      Hc_addresscity: companyCustomData.hc_addresscity || "",
      Hc_addressstate: companyCustomData.hc_addressstate || "",
      Hc_addressstreet: companyCustomData.hc_addressstreet || "",
      Hc_addresszippostalcode: companyCustomData.hc_addresszippostalcode || "",
      Hc_businessownersssn: companyCustomData.hc_businessownersssn || "",
      new_name: companyCustomData.new_name || "",
      Hc_contact: companyCustomData.hc_contact || "",
      Hc_email: companyCustomData.hc_email || "",
      Hc_employeecount: companyCustomData.hc_employeecount || "",
      Hc_website: companyCustomData.hc_website || "",
      Hc_rundate: companyCustomData.hc_rundate || "",
    }),
    [companyCustomData]
  );

  // 🔄 Reset Confirmation Modal whenever `isOpen` changes
  useEffect(() => {
    if (isOpen) {
      setShowConfirmationModal(true);
      setUpdatedPdfUrl(null); // Reset PDF when reopening for a new document
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchUserSignature = async () => {
      if (isOpen && !showConfirmationModal) {
        const signature = await fetchSignature();
        if (signature) {
          console.log("Fetched Signature:", signature);
          setFetchedSignature(signature);

          prepopulatePdf(documentUrl, prepopulateData, signature).then(
            (pdfBytes) => {
              const blob = new Blob([pdfBytes], { type: "application/pdf" });
              const blobUrl = URL.createObjectURL(blob);
              setUpdatedPdfUrl(blobUrl);
              logPdfFormFields(blobUrl);
            }
          );
        } else {
          console.warn("No signature found for this user.");
        }
      }
    };

    fetchUserSignature();
  }, [isOpen, showConfirmationModal]);

  const handleConfirmUpload = async () => {
    setIsUploading(true);

    try {
      const response = await fetch(updatedPdfUrl);
      const pdfBytes = await response.arrayBuffer();

      const pdfDoc = await PDFDocument.load(pdfBytes);

      const form = pdfDoc.getForm();
      form.flatten(); // Flatten locks the content to prevent further edits

      const flattenedPdfBytes = await pdfDoc.save();

      const blob = new Blob([flattenedPdfBytes], { type: "application/pdf" });
      const originalExtension = documentUrl.split(".").pop();
      const newFileName =
        documentName && documentName.includes(".")
          ? documentName
          : `${documentName || "esign_document"}.${originalExtension}`;
      const file = new File([blob], newFileName, {
        type: blob.type,
        lastModified: Date.now(),
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("dealId", dealId);
      formData.append("locationName", franchiseName || "");
      formData.append("companyName", companyCustomData.new_name || "");

      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/sharepoint-documents/upload`;
      const uploadResponse = await axios.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (uploadResponse.status === 200) {
        toast.success("Document uploaded successfully!");

        if (crmReference) {
          try {
            await updateDocumentStatus(dealId, crmReference, "122990103");
            toast.success("Document status updated successfully in CRM!");
          } catch (statusError) {
            console.error("CRM status update failed:", statusError);
            toast.error("Failed to update CRM status.");
          }
        } else {
          console.warn("CRM Reference is missing.");
        }

        onClose();
      } else {
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error during document upload:", error);
      toast.error("Failed to upload document or update status.");
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      {showConfirmationModal && (
        <div className="confirmation-modal">
          <p>
            <strong>Notice:</strong> By confirming, you agree that your
            signature will be used to sign this document and is legally binding.
          </p>
          <div className="confirmation-buttons">
            <button
              className="confirm-button"
              onClick={() => setShowConfirmationModal(false)}
            >
              Confirm
            </button>
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {!showConfirmationModal && (
        <div className="pdf-container">
          <iframe
            src={updatedPdfUrl}
            key={updatedPdfUrl}
            className="pdf-viewer"
            title="Updated eSign Document"
          ></iframe>
          <div className="modal-buttons">
            <button
              className="confirm-button"
              onClick={handleConfirmUpload}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Confirm"}
            </button>
            <button
              className="cancel-button"
              onClick={onClose}
              disabled={isUploading}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>,
    document.getElementById("modal-root")
  );
};

export default ESignModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-documents {
    margin-top: 20px;
  }
  
  .view-documents h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .documents-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
  }
  
  .documents-table th,
  .documents-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .documents-table th {
    background-color: #193768;
    color: white;
    text-align: left;
  }
  
  /* Fixed widths for columns */
  .documents-table th:first-child,
  .documents-table td:first-child {
    width: 40%; /* 40% width for the Document Name column */
  }
  
  .documents-table th:nth-child(2),
  .documents-table td:nth-child(2) {
    width: 30%; /* 30% width for the Last Updated column */
  }
  
  .documents-table th:nth-child(3),
  .documents-table td:nth-child(3),
  .documents-table th:nth-child(4),
  .documents-table td:nth-child(4) {
    width: 15%; /* 15% width for the View and Remove columns */
    text-align: center;
  }
  
  .table-icon {
    text-align: center;
  }
  
  .table-icon .material-symbols-outlined {
    cursor: pointer;
    font-size: 24px;
    color: #263f68;
  }
  
  .table-icon .material-symbols-outlined:hover {
    color: #263f68;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ViewDocuments/ViewDocuments.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;EAClB;;EAEA,6BAA6B;EAC7B;;IAEE,UAAU,EAAE,2CAA2C;EACzD;;EAEA;;IAEE,UAAU,EAAE,0CAA0C;EACxD;;EAEA;;;;IAIE,UAAU,EAAE,8CAA8C;IAC1D,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".view-documents {\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .view-documents h3 {\r\n    font-size: 20px;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .documents-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    background-color: #ffffff;\r\n  }\r\n  \r\n  .documents-table th,\r\n  .documents-table td {\r\n    padding: 12px;\r\n    border: 1px solid #ddd;\r\n    text-align: left;\r\n  }\r\n  \r\n  .documents-table th {\r\n    background-color: #193768;\r\n    color: white;\r\n    text-align: left;\r\n  }\r\n  \r\n  /* Fixed widths for columns */\r\n  .documents-table th:first-child,\r\n  .documents-table td:first-child {\r\n    width: 40%; /* 40% width for the Document Name column */\r\n  }\r\n  \r\n  .documents-table th:nth-child(2),\r\n  .documents-table td:nth-child(2) {\r\n    width: 30%; /* 30% width for the Last Updated column */\r\n  }\r\n  \r\n  .documents-table th:nth-child(3),\r\n  .documents-table td:nth-child(3),\r\n  .documents-table th:nth-child(4),\r\n  .documents-table td:nth-child(4) {\r\n    width: 15%; /* 15% width for the View and Remove columns */\r\n    text-align: center;\r\n  }\r\n  \r\n  .table-icon {\r\n    text-align: center;\r\n  }\r\n  \r\n  .table-icon .material-symbols-outlined {\r\n    cursor: pointer;\r\n    font-size: 24px;\r\n    color: #263f68;\r\n  }\r\n  \r\n  .table-icon .material-symbols-outlined:hover {\r\n    color: #263f68;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

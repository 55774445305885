// src/services/encryptEmail.js
import CryptoJS from 'crypto-js';

export const encryptEmail = (email) => {
  try {
    // Generate a random IV
    const iv = CryptoJS.lib.WordArray.random(16);
    const encryptionKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);

    // Encrypt the email
    const encrypted = CryptoJS.AES.encrypt(email, encryptionKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert IV and encrypted data to hex strings
    const ivHex = iv.toString(CryptoJS.enc.Hex);
    const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

    return `${ivHex}:${encryptedHex}`;
  } catch (error) {
    console.error('Error encrypting email:', error);
    return null;
  }
};

import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/howto/topics`;

/**
 * Fetch all How-To topics from the backend
 * @returns {Promise} List of topics
 */
export const fetchHowToTopics = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching How-To topics:", error);
    return [];
  }
};

/**
 * Add a new How-To topic
 * @param {string} title - The new topic title (adjusted key from `topic` → `title`)
 * @returns {Promise} Response from backend
 */
export const addNewTopic = async (title) => {
  try {
    const response = await axios.post(API_BASE_URL, { title }); // ✅ Match backend `title` field
    return response.data;
  } catch (error) {
    console.error("Error adding new topic:", error);
    throw error;
  }
};

import React from "react";
import { useSelector } from "react-redux";
import CompanyDashboard from "../CompanyDashboard/CompanyDashboard";
import DocumentViewer from "../DocumentViewer/DocumentViewer";
import DocumentManager from "../DocumentManager/DocumentManager";
import UserManager from "../UserManager/UserManager"; // Import the new component
import "./MainContent.css";

const MainContent = ({
  deals,
  selectedCompany,
  handleCompanyChange,
  clearSearch,
  searchTerm,
  setSearchTerm,
  showDropdown,
  setShowDropdown,
  stateId, // Receive stateId from parent
  setStateId, // Receive setStateId to update
}) => {
  const { activeComponent, documentName } = useSelector(
    (state) => state.activeComponent
  );

  let content;
  switch (activeComponent) {
    case "companyDashboard":
      content = (
        <CompanyDashboard
          deals={deals}
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange}
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          stateId={stateId} // Pass stateId to CompanyDashboard
          setStateId={setStateId} // Pass setStateId to update it from CompanyDashboard
        />
      );
      break;
    case "documentViewer":
      content = <DocumentViewer documentName={documentName} />;
      break;
    case "documentManager":
      content = <DocumentManager />;
      break;
    case "userManager":
      content = <UserManager />;
      break;
    default:
      content = <CompanyDashboard deals={deals} />;
  }

  return <div className="main-content">{content}</div>;
};

export default MainContent;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import createEsignature from "../../services/createEsignature";
import createAcknowledgement from "../../services/createAcknowledgement";
import "./SignaturePromptModal.css";

const SignaturePromptModal = ({ isOpen, onClose, onConfirm }) => {
  const [signature, setSignature] = useState("");
  const [hashedEmail, setHashedEmail] = useState("");
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  // Hashing function for email (matches backend logic)
  async function hashEmail(email) {
    const encoder = new TextEncoder();
    const data = encoder.encode(email.trim().toLowerCase());
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
    return hashHex;
  }

  const handleConfirm = async () => {
    const email = sessionStorage.getItem("email");
    console.log("Handling Confirmation");

    if (!email) {
      console.log("Failed fetching email from session");
      alert("Failed to retrieve email. Please log in again.");
      return;
    }

    const hashedEmail = await hashEmail(email);
    console.log("Hashed Email:", hashedEmail);
    setHashedEmail(hashedEmail);

    if (!signature.trim()) {
      alert("Please enter a valid signature");
      return;
    }

    if (!hashedEmail) {
      alert("Failed to retrieve email hash. Please log in again.");
      return;
    }

    if (!isAcknowledged) {
      alert("Please acknowledge that your full name will be used for legally binding documents.");
      return;
    }

    try {
      // Step 1: Send Acknowledgement
      await createAcknowledgement(hashedEmail);
      console.log("Acknowledgement created successfully");

      // Step 2: Send E-signature
      const payload = {
        hashed_email: hashedEmail,
        signature,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const result = await createEsignature(payload);
      console.log("E-signature saved:", result);

      if (typeof onConfirm === "function") {
        onConfirm(signature);
      }
      if (typeof onClose === "function") {
        onClose();
      }
    } catch (error) {
      console.error("Error saving signature or creating acknowledgement:", error);

      // Handle Acknowledgement Conflict
      if (error.response && error.response.status === 409) {
        alert("Acknowledgement already exists. You can proceed.");
      } else {
        alert("Failed to save signature. Please try again.");
      }
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="signature-modal-overlay">
      <div className="signature-modal-content">
        <h2>Welcome!</h2>
        <p>
          We're thrilled to have you onboard and excited to accompany you on your journey.
        </p>
        <p>
          As part of our secure digital signing process, we require your full name (first and last) 
          to generate a personalized e-signature.
        </p>
        <p>
          This signature not only verifies your identity but also ensures the authenticity and 
          integrity of your documents.
        </p>
        <p>
          Please enter your complete name below so we can securely prepare your digital signature.
        </p>

        {/* Signature Input */}
        <input
          type="text"
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
          placeholder="Enter your signature"
        />

        {/* Acknowledgement Message */}
        <p className="acknowledgement-message">
          <strong>Note:</strong> By proceeding, you acknowledge that your full
          name will be used to sign documents and that these documents are
          legally binding.
        </p>

        {/* Checkbox for Acknowledgement */}
        <div className="checkbox-row">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAcknowledged}
              onChange={(e) => setIsAcknowledged(e.target.checked)}
            />
            I acknowledge and agree to the above terms.
          </label>
        </div>

        {/* Confirm Button */}
        <div className="signature-modal-buttons">
          <button
            onClick={handleConfirm}
            disabled={!isAcknowledged} // Disable until acknowledged
          >
            Confirm
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default SignaturePromptModal;

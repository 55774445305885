import { createSlice } from "@reduxjs/toolkit";

// Helper function to initialize auth state from localStorage
const rehydrateAuthState = () => {
  const authData = JSON.parse(localStorage.getItem("authData"));
  if (authData && authData.token && authData.user) {
    return {
      isAuthenticated: true,
      user: authData.user,
      error: null,
    };
  }
  console.log('Uaser Auth Data: ', authData)
  return {
    isAuthenticated: false,
    user: null,
    error: null,
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState: rehydrateAuthState(),
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = {
        ...action.payload.user, // Include all user details from payload
        role: action.payload.user.role || "user", // Default role to "user" if undefined
      };
      state.error = null;

      // Save token and user to localStorage
      const authData = {
        token: action.payload.token,
        user: state.user,
      };
      localStorage.setItem("authData", JSON.stringify(authData));
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;

      // Clear auth data from localStorage
      localStorage.removeItem("authData");
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;

      // Clear auth data from localStorage
      localStorage.removeItem("authData");
    },
    authenticateViaMagicLinkSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = {
        ...action.payload, // Include user details from the magic link payload
        role: action.payload.role || "user", // Default role to "user" if undefined
      };
      state.error = null;

      // Save user data to localStorage
      const authData = {
        token: action.payload.token || "", // Include token if available
        user: state.user,
      };
      localStorage.setItem("authData", JSON.stringify(authData));
    },
    authenticateViaMagicLinkFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;

      // Clear auth data from localStorage
      localStorage.removeItem("authData");
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  authenticateViaMagicLinkSuccess,
  authenticateViaMagicLinkFailure,
} = authSlice.actions;

export const authenticateViaMagicLink = (data) => (dispatch) => {
  try {
    const { email, role, token } = data; // Ensure role and token are included in payload
    const userPayload = { email, role, token };
    dispatch(authenticateViaMagicLinkSuccess(userPayload));
  } catch (error) {
    console.error("Error processing authentication: ", error);
    dispatch(authenticateViaMagicLinkFailure("Token validation failed"));
  }
};

export default authSlice.reducer;

import axios from "axios";

// Base API URL
const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/howto/entries`;

/**
 * Fetch all How-To entries from the backend
 * @returns {Promise} List of How-To entries
 */
export const fetchHowToList = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching How-To data:", error);
    return [];
  }
};

/**
 * Upload a new How-To entry with a file
 * @param {number} topic_id - The topic ID
 * @param {File} file - The file to upload
 * @param {string} userId - ID of the user uploading
 * @returns {Promise} Response from backend
 */
export const uploadHowToEntry = async (topic_id, file, userId) => {
  const formData = new FormData();
  formData.append("topic_id", topic_id);
  formData.append("file", file);
  formData.append("file_name", file.name);
  formData.append("user_id", userId);

  try {
    const response = await axios.post(API_BASE_URL, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading How-To entry:", error);
    throw error;
  }
};

const fetchGeneralDocuments = async (crmReferences = []) => {
  try {
    if (!Array.isArray(crmReferences) || crmReferences.length === 0) {
      console.warn("No CRM References provided for general documents. Skipping.");
      return [];
    }

    const queryParams = new URLSearchParams({
      crmReferences: crmReferences.join(","),
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/documents/general?${queryParams.toString()}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch general documents");
    }

    const data = await response.json();
    // console.log("Fetched general documents:", data);

    return data.map((doc) => ({
      document_id: doc.document_id,
      document_name: doc.document_name,
      file_path: doc.file_path,
      section: doc.section,
      crmReferences: doc.crm_reference,
    }));
  } catch (error) {
    console.error("Error fetching general documents:", error);
    return [];
  }
};

export default fetchGeneralDocuments;

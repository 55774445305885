import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { showSuccessToast, showErrorToast, showWarningToast } from "../../services/toastConfig";
import DocumentModal from "../DocumentModal/DocumentModal";
import ESignModal from "../TempModal/TempModal";
import UploadDocumentModal from "../UploadDocumentModal/UploadDocumentModal";
import updateDocumentStatus from "../../services/updateDocumentStatus";
import fetchCrmReference from "../../services/fetchCRMReference";
import fetchDocumentDetails from "../../services/fetchDocumentDetails";
import * as pdfjsLib from "pdfjs-dist/webpack";
import "./DocumentActions.css";

const DocumentActions = ({ documentName, filePath, onUploadSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isESignModalOpen, setIsESignModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [crmReference, setCrmReference] = useState("");
  const [isFetchingCrmReference, setIsFetchingCrmReference] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    description: "Fetching...",
    purpose: "Fetching...",
  });
  const dealId = useSelector((state) => state.deal.dealId);

  /**
   * Fetch CRM reference when documentName changes.
   */
  useEffect(() => {
    const fetchReference = async () => {
      if (!documentName) return;

      try {
        setIsFetchingCrmReference(true);
        const reference = await fetchCrmReference(documentName);
        setCrmReference(reference);
      } catch (error) {
        console.error("Error fetching CRM reference:", error);
        setCrmReference("");
      } finally {
        setIsFetchingCrmReference(false);
      }
    };

    fetchReference();
  }, [documentName]);

  /**
   * Fetch document details using CRM reference.
   */
  useEffect(() => {
    const fetchDetails = async () => {
      if (!crmReference) {
        setDocumentDetails({
          description: "CRM reference not provided.",
          purpose: "CRM reference not provided.",
        });
        return;
      }

      try {
        const details = await fetchDocumentDetails(crmReference);
        setDocumentDetails({
          description: details.description || "No description available.",
          purpose: details.purpose || "No purpose available.",
        });
      } catch (error) {
        showErrorToast("Error fetching document details.");
        setDocumentDetails({
          description: "Failed to fetch description.",
          purpose: "Failed to fetch purpose.",
        });
      }
    };

    fetchDetails();
  }, [crmReference]);

  /**
   * Generate a thumbnail for the document.
   */
  useEffect(() => {
    const generateThumbnail = async () => {
      if (filePath) {
        try {
          const loadingTask = pdfjsLib.getDocument(filePath);
          const pdf = await loadingTask.promise;
          const page = await pdf.getPage(1);
          const scale = 0.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          setThumbnail(canvas.toDataURL());
        } catch (error) {
          console.error("Error generating thumbnail:", error);
        }
      } else {
        console.warn("No filePath provided for generating thumbnail.");
        setThumbnail(null);
      }
    };

    generateThumbnail();
  }, [filePath]);

  const handleViewDocument = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleESignDocument = () => {
    // Disabled for now, no action needed
  };

  const handleUploadSingle = () => {
    setIsBulkUpload(false);
    setIsUploadModalOpen(true);
  };

  const handleUploadBulk = () => {
    setIsBulkUpload(true);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleConfirmUpload = async (files, tags) => {
    if (files && files.length > 0) {
      try {
        if (crmReference) {
          await updateDocumentStatus(dealId, crmReference, "122990103");
          showSuccessToast("Document status updated successfully in CRM!");
        } else {
          console.warn("CRM Reference is missing.");
        }
      } catch (error) {
        console.error("Error in file upload process or status update:", error);
        showErrorToast("Failed to upload file or update status. Please try again.");
      }
    } else {
      console.warn("No files selected for upload.");
      showWarningToast("Please select a file to upload.");
    }
    setIsUploadModalOpen(false);
  };

  return (
    <div className="document-actions">
      <div className="action-buttons">
        <h3>{documentName}</h3>
        <button
          onClick={handleViewDocument}
          disabled={!filePath}
          className={!filePath ? "disabled" : ""}
        >
          View Document
        </button>
        <button onClick={handleUploadSingle}>Upload Single</button>
        <button onClick={handleUploadBulk}>Upload Bulk</button>
        <button
          onClick={handleESignDocument}
          disabled={true}
          className="disabled"
        >
          E-sign Document
        </button>
      </div>
      <div className="document-thumbnail-container">
        <h3>Document Thumbnail</h3>
        <div className="document-thumbnail">
          {filePath ? (
            thumbnail ? (
              <img src={thumbnail} alt="PDF Thumbnail" />
            ) : (
              <div className="thumbnail-placeholder">Loading...</div>
            )
          ) : (
            <div className="no-thumbnail-placeholder">No thumbnail available</div>
          )}
        </div>
      </div>
      <div className="document-details">
        <h3>Document Information</h3>
        <p>
          <strong>Document Description:</strong> {documentDetails.description}
        </p>
        <p>
          <strong>Document Purpose:</strong> {documentDetails.purpose}
        </p>
      </div>

      <DocumentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        documentUrl={filePath}
      />

      <ESignModal
        isOpen={isESignModalOpen}
        onClose={() => setIsESignModalOpen(false)}
        documentUrl={filePath}
      />

      <UploadDocumentModal
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onConfirm={handleConfirmUpload}
        isBulkUpload={isBulkUpload}
        dealId={dealId}
        onUploadSuccess={onUploadSuccess}
        crmReference={crmReference}
        documentName={documentName}
      />
    </div>
  );
};

export default DocumentActions;

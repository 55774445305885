import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/howto/entries`;

export const deleteHowToEntry = async (entryId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${entryId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting entry:", error);
    throw error;
  }
};

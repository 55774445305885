// src/components/UserManagement/UserManagement.js
import React, { useState, useEffect } from "react";
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
} from "../../services/manageUsers";
import UserEditModal from "./UserEditModal";
import { decryptEmail } from "../../services/decryptEmail";
import { encryptEmail } from "../../services/encryptEmail"; // Import the encryption function
import "./UserManager.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    email: "",
    role: "",
    organization: "",
    owning_team: "",
    validated: 0,
  });
  const [editingUser, setEditingUser] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const data = await fetchUsers();
      setUsers(data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const generatePassword = () => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    const password = Array(10)
      .fill()
      .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
      .join("");
    setNewUser({ ...newUser, password });
  };

  const handleCreateUser = async () => {
    try {
      // Encrypt email before creating a new user
      const encryptedNewUser = {
        ...newUser,
        email: encryptEmail(newUser.email),
      };
      await createUser(encryptedNewUser);
      await loadUsers();
      setNewUser({
        username: "",
        password: "",
        email: "",
        role: "",
        organization: "",
        owning_team: "",
        validated: 0,
      });
    } catch (error) {
      console.error("Failed to create user:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      // Encrypt email before updating user
      const updatedUser = {
        ...editingUser,
        email: encryptEmail(editingUser.email),
      };
      await updateUser(editingUser.id, updatedUser);
      await loadUsers();
      setEditingUser(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      setUsers(users.filter((user) => user?.id !== id));
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const openEditModal = (user) => {
    setEditingUser({
      ...user,
      email: user.email ? decryptEmail(user.email) : "", // Decrypt email before setting it in state
    });
    setIsModalOpen(true);
  };

  return (
    <div className="user-management">
      <h2>User Management</h2>

      <div className="add-user-form">
        <h3>Add New User</h3>
        <div className="form-row">
          <div className="left-column">
            <input
              type="text"
              placeholder="Username"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
              className="username-input"
            />
            <select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <option value="">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="Manager">Manager</option>
              <option value="User">User</option>
            </select>
          </div>
          <div className="middle-column">
            <input
              type="email"
              placeholder="Email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              className="user-email-input"
            />
            <select
              value={newUser.organization}
              onChange={(e) =>
                setNewUser({ ...newUser, organization: e.target.value })
              }
            >
              <option value="">Select Organization</option>
              <option value="Organization 1">Organization 1</option>
              <option value="Organization 2">Organization 2</option>
              <option value="Organization 3">Organization 3</option>
            </select>
          </div>
          <div className="right-column">
            <div className="password-field">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={newUser.password}
                className="password-input"
                readOnly
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
              <button
                onClick={generatePassword}
                className="generate-password-button"
              >
                Generate
              </button>
            </div>
            <select
              value={newUser.owning_team}
              onChange={(e) =>
                setNewUser({ ...newUser, owning_team: e.target.value })
              }
            >
              <option value="">Select Owning Team</option>
              <option value="Team 1">Team 1</option>
              <option value="Team 2">Team 2</option>
              <option value="Team 3">Team 3</option>
            </select>
          </div>
        </div>
        <button onClick={handleCreateUser}>Add User</button>
      </div>

      <div className="user-list">
        <h3>Current Users</h3>
        <table className="user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Organization</th>
              <th>Owning Team</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user?.id}>
                {editingUser?.id === user?.id ? (
                  <>
                    <td>
                      <input
                        type="text"
                        value={editingUser.username}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            username: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      {user?.email
                        ? decryptEmail(user.email)
                        : "Email unavailable"}
                    </td>
                    <td>
                      <select
                        value={editingUser.role}
                        onChange={(e) =>
                          setEditingUser({
                            ...editingUser,
                            role: e.target.value,
                          })
                        }
                      >
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="User">User</option>
                      </select>
                    </td>
                    <td>{user?.organization ?? "N/A"}</td>
                    <td>{user?.owning_team ?? "N/A"}</td>
                    <td>
                      <button onClick={() => handleUpdateUser(user?.id)}>
                        Save
                      </button>
                      <button onClick={() => setEditingUser(null)}>
                        Cancel
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{user?.username ?? "N/A"}</td>
                    <td>
                      {user?.email
                        ? decryptEmail(user.email)
                        : "Email unavailable"}
                    </td>
                    <td>{user?.role ?? "N/A"}</td>
                    <td>{user?.organization ?? "N/A"}</td>
                    <td>{user?.owning_team ?? "N/A"}</td>
                    <td>
                      <button onClick={() => openEditModal(user)}>Edit</button>
                      <button
                        className="users-delete-button"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UserEditModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        editingUser={editingUser}
        setEditingUser={setEditingUser}
        handleUpdateUser={handleUpdateUser}
      />
    </div>
  );
};

export default UserManagement;

import React from 'react';
import ReactDOM from 'react-dom';
import './DeleteConfirmationModal.css'; // Add your styles here

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="delete-modal-overlay">
      <div className="delete-modal-content">
        <h4>Are you sure you want to delete this document?</h4>
        <div className="delete-modal-buttons">
          <button className="delete-confirm-button" onClick={onConfirm}>Yes</button>
          <button className="delete-cancel-button" onClick={onClose}>No</button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') // Ensure this matches your modal root in HTML
  );
};

export default DeleteConfirmationModal;

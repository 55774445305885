// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import activeComponentReducer from './slices/activeComponentSlice';
import organizationReducer from './slices/organizationSlice';
import authReducer from './slices/authSlice'; // Import the auth reducer

export const store = configureStore({
  reducer: {
    activeComponent: activeComponentReducer,
    organization: organizationReducer,
    auth: authReducer, // Add the auth reducer here
  },
});

export default store;

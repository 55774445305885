import axios from "axios";

const checkAcknowledgement = async (hashed_email) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/acknowledgements/check`,
            { hashed_email },
            { headers: { "Content-Type": "application/json" } }
        );
        return response.data; // Successfully found acknowledgement
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null; // No acknowledgement found
        }
        console.error("Error checking acknowledgement:", error);
        throw error;
    }
};

export default checkAcknowledgement;

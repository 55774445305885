// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
    flex: 1 1;
    padding: 2rem;
    overflow-y: auto;
    background-color: #ececec;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    .main-content {
      margin-left: 0;
      padding: 1rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MainContent/MainContent.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE;MACE,cAAc;MACd,aAAa;IACf;EACF","sourcesContent":[".main-content {\r\n    flex: 1;\r\n    padding: 2rem;\r\n    overflow-y: auto;\r\n    background-color: #ececec;\r\n    overflow-y: auto;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .main-content {\r\n      margin-left: 0;\r\n      padding: 1rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./AddDocument.css";
import customers from "../../data/customers.json";
import Modal from "react-modal";
import {
  addSection,
  fetchSections as fetchSectionsAPI,
} from "../../services/sectionService";

const AddDocument = ({ documentType, setDocumentType, fetchDocuments }) => {
  const [documentName, setDocumentName] = useState("");
  const [description, setDescription] = useState(""); // New State for Description
  const [purpose, setPurpose] = useState(""); // New State for Purpose
  const [section, setSection] = useState("");
  const [sections, setSections] = useState([]);
  const [newSection, setNewSection] = useState("");
  const [showNewSectionInput, setShowNewSectionInput] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedFranchise, setSelectedFranchise] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [crmFieldLogicalName, setCrmFieldLogicalName] = useState(""); // New State for CRM Field Logical Name
  const [file, setFile] = useState(null);
  const [editingDocument, setEditingDocument] = useState(null);
  const [franchises, setFranchises] = useState([]);
  const [states, setStates] = useState([]);
  const [showFileWarningModal, setShowFileWarningModal] = useState(false);
  const [proceedWithoutFile, setProceedWithoutFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFranchiseModal, setShowFranchiseModal] = useState(false);
  const [showStateModal, setShowStateModal] = useState(false);
  const [showGeneralModal, setShowGeneralModal] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const openGeneralModal = () => setShowGeneralModal(true);
  const closeGeneralModal = () => setShowGeneralModal(false);
  const openFranchiseModal = () => setShowFranchiseModal(true);
  const closeFranchiseModal = () => setShowFranchiseModal(false);
  const openStateModal = () => setShowStateModal(true);
  const closeStateModal = () => setShowStateModal(false);

  const fetchSections = async () => {
    try {
      const data = await fetchSectionsAPI();
      setSections(Array.isArray(data) ? data : []);
    } catch (error) {
      setSections([]);
    }
  };

  const handleAddNewSection = async () => {
    if (!newSection.trim()) return;
    try {
      const addedSection = await addSection(newSection);
      setSections((prevSections) => [...prevSections, addedSection]);
      setSection(addedSection.section);
      setNewSection("");
      setShowNewSectionInput(false);
      toast.success("Section added successfully!");
    } catch (error) {
      toast.error("Failed to add section. Please try again.");
    }
  };

  const fetchFranchises = async () => {
    try {
      const response = await fetch(`${apiUrl}/franchises`);
      if (!response.ok)
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        setFranchises(Array.isArray(data) ? data : []);
      } else {
        throw new Error("Received non-JSON response");
      }
    } catch (error) {
      setFranchises([]);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await fetch(`${apiUrl}/states`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setStates(data);
      } else {
        setStates([]);
      }
    } catch (error) {
      setStates([]);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (documentType === "Franchise Document") {
      fetchFranchises();
    } else if (documentType === "State Document") {
      fetchStates();
    }
  }, [documentType]);

  const handleAddOrUpdateDocument = async () => {
    console.log("Document Name:", documentName);
    console.log("Section:", section);
    console.log("CRM Field Logical Name:", crmFieldLogicalName);
    console.log("Description:", description);
    console.log("Purpose:", purpose);
    console.log("Document Type:", documentType);
    console.log("Selected Franchise:", selectedFranchise);
    console.log("Selected State:", selectedState);
    console.log("File:", file);

    if (
      !documentName ||
      !section ||
      !crmFieldLogicalName ||
      !description ||
      !purpose ||
      (documentType === "Franchise Document" && !selectedFranchise) ||
      (documentType === "State Document" && !selectedState)
    ) {
      console.error("Validation failed. Missing fields:");
      if (!documentName) console.error("Missing: Document Name");
      if (!section) console.error("Missing: Section");
      if (!crmFieldLogicalName)
        console.error("Missing: CRM Field Logical Name");
      if (!description) console.error("Missing: Description");
      if (!purpose) console.error("Missing: Purpose");
      if (documentType === "Franchise Document" && !selectedFranchise)
        console.error("Missing: Selected Franchise for Franchise Document");
      if (documentType === "State Document" && !selectedState)
        console.error("Missing: Selected State for State Document");
      toast.error("Please fill in all fields.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    if (!file && !proceedWithoutFile) {
      console.warn(
        "No file provided and user chose not to proceed without a file."
      );
      setShowFileWarningModal(true);
      return;
    }

    const formData = new FormData();
    formData.append("documentName", documentName);
    formData.append("description", description);
    formData.append("purpose", purpose);
    formData.append("documentType", documentType);
    formData.append("section", section);
    formData.append("crm_reference", crmFieldLogicalName);
    if (file) formData.append("file", file);

    if (documentType === "Additional Document") {
      const selectedCompany = customers.find(
        (c) => c.companyId === selectedCustomer
      );
      formData.append("companyId", selectedCustomer);
      formData.append(
        "companyName",
        selectedCompany ? selectedCompany.companyName : ""
      );
    } else if (documentType === "Franchise Document") {
      formData.append("franchiseId", selectedFranchise);
    } else if (documentType === "State Document") {
      formData.append("stateId", selectedState);
    }

    console.log("Form Data being sent:", Array.from(formData.entries()));

    try {
      setLoading(true);
      const method = editingDocument ? "PUT" : "POST";
      const url = editingDocument
        ? `${apiUrl}/documents/${editingDocument.document_id}`
        : `${apiUrl}/documents`;

      console.log("API URL:", url);
      console.log("HTTP Method:", method);

      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (response.ok) {
        console.log("Document successfully added/updated.");
        toast.success(
          `Document ${editingDocument ? "updated" : "added"} successfully!`,
          {
            position: "top-center",
            autoClose: 3000,
          }
        );
        await fetchDocuments();
        resetForm();
      } else {
        console.error("Error adding/updating document. Response:", response);
        toast.error("Error adding/updating document.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Server error:", error);
      toast.error("Server error. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setDocumentName("");
    setDescription("");
    setPurpose("");
    setSection("");
    setCrmFieldLogicalName("");
    setSelectedCustomer("");
    setSelectedFranchise("");
    setSelectedState("");
    setFile(null);
    setDocumentType("Select document type");
    setEditingDocument(null);
    setProceedWithoutFile(false);
    const fileInput = document.getElementById("file");
    if (fileInput) fileInput.value = "";
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setNewSection("");
  };

  const handleSaveSection = () => {
    if (newSection.trim()) {
      handleAddNewSection(newSection);
      closeModal();
    }
  };

  Modal.setAppElement("#root");

  return (
    <div className="add-document">
      <h3>{editingDocument ? "Edit" : "Add"} Document</h3>
      <div className="add-document-form">
        <div className="field-grouping">
          <label htmlFor="document-type">Document Type</label>
          <select
            id="document-type"
            value={documentType}
            onChange={(e) => {
              const selectedType = e.target.value;
              console.log("Selected Document Type:", selectedType);
              setDocumentType(selectedType);

              // Trigger modals based on selected document type
              if (selectedType === "Franchise Document") {
                openFranchiseModal();
              } else if (selectedType === "State Document") {
                openStateModal();
              } else if (
                selectedType === "General Document" ||
                selectedType === "Additional Document"
              ) {
                console.log("Opening General/Additional Document Modal");
                openGeneralModal();
              }
            }}
          >
            <option value="">Select a document type...</option>
            <option value="State Document">
              State Document (State specific)
            </option>
            <option value="Franchise Document">
              Franchise Document (Franchise specific)
            </option>
            <option value="General Document">
              General Document (Accessible by all franchisees)
            </option>
            <option value="Additional Document">
              Additional Document (Specific to customer)
            </option>
          </select>
          <label htmlFor="document-name">Document Name</label>
          <input
            type="text"
            id="document-name"
            placeholder="Enter document name"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
        </div>
        <div className="field-grouping">
          <label htmlFor="description">Document Description</label>
          <textarea
            id="description"
            placeholder="Enter document description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <label htmlFor="purpose">Document Purpose</label>
          <textarea
            id="purpose"
            placeholder="Enter document purpose"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
        </div>
        <div className="field-grouping">
          <label htmlFor="section">Menu Section</label>
          <div className="section-select">
            <select
              id="section"
              className="section-select"
              value={section}
              onChange={(e) => {
                if (e.target.value === "add-new") {
                  openModal();
                } else {
                  setSection(e.target.value);
                }
              }}
            >
              <option value="">Select Section</option>
              {sections.map((sec, index) => (
                <option key={index} value={sec.section}>
                  {sec.section}
                </option>
              ))}
              <option value="add-new">+ Add New Section</option>
            </select>
          </div>

          {/* Modal for adding a new section */}
          <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            contentLabel="Add New Section"
            className="new-section-modal"
            overlayClassName="new-section-overlay"
          >
            <div className="new-section-modal-content">
              <h2>Add New Section</h2>
              <input
                type="text"
                value={newSection}
                onChange={(e) => setNewSection(e.target.value)}
                placeholder="Enter new section name"
              />
              <div className="new-section-modal-actions">
                <button onClick={handleSaveSection}>Save</button>
                <button onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </Modal>

          {/* Franchise-specific Modal */}
          <Modal
            isOpen={showFranchiseModal}
            onRequestClose={closeFranchiseModal}
            contentLabel="Select Franchise"
            className="franchise-modal"
            overlayClassName="franchise-overlay"
          >
            <div className="franchise-modal-content">
              <h2>Select Franchise</h2>
              <select
                id="franchise-select"
                value={selectedFranchise}
                onChange={(e) => setSelectedFranchise(e.target.value)}
              >
                <option value="">Select Franchise</option>
                {franchises.map((franchise) => (
                  <option
                    key={franchise.franchise_id}
                    value={franchise.franchise_id}
                  >
                    {franchise.franchise_name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id="crm-logical-name"
                placeholder="Enter CRM Field Logical Name"
                value={crmFieldLogicalName}
                onChange={(e) => setCrmFieldLogicalName(e.target.value)}
              />
              <button onClick={closeFranchiseModal}>Save and Close</button>
            </div>
          </Modal>

          {/* State-specific Modal */}
          <Modal
            isOpen={showStateModal}
            onRequestClose={closeStateModal}
            contentLabel="Select State"
            className="state-modal"
            overlayClassName="state-overlay"
          >
            <div className="state-modal-content">
              <h2>Select State</h2>
              <select
                id="state-select"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                id="crm-logical-name"
                placeholder="Enter CRM Field Logical Name"
                value={crmFieldLogicalName}
                onChange={(e) => setCrmFieldLogicalName(e.target.value)}
              />
              <button onClick={closeStateModal}>Save and Close</button>
            </div>
          </Modal>

          {/* General/Additional Document Modal */}
          <Modal
            isOpen={showGeneralModal}
            onRequestClose={closeGeneralModal}
            contentLabel="General or Additional Document CRM Reference"
            className="general-modal"
            overlayClassName="general-overlay"
          >
            <div className="general-modal-content">
              <h2>Enter CRM Field Logical Name</h2>
              <div className="input-group">
                <input
                  type="text"
                  id="crm-logical-name"
                  placeholder="Enter CRM Field Logical Name"
                  value={crmFieldLogicalName}
                  onChange={(e) => setCrmFieldLogicalName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <button onClick={closeGeneralModal}>Save and Close</button>
              </div>
            </div>
          </Modal>

          {/* File upload */}
          <label htmlFor="file">Upload Document</label>
          <input
            type="file"
            id="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>

        <button
          className="add-document-button"
          onClick={handleAddOrUpdateDocument}
          disabled={loading}
        >
          {loading ? "Uploading Document..." : "Add Document"}
        </button>
      </div>
      <Modal
        isOpen={showFileWarningModal}
        onRequestClose={() => setShowFileWarningModal(false)}
        contentLabel="File Warning"
        className="file-warning-modal"
        overlayClassName="file-warning-overlay"
      >
        <div className="file-warning-modal-content">
          <h2>No File Selected</h2>
          <p>
            You haven't selected a file to upload. Would you like to proceed
            without uploading a file?
          </p>
          <div className="file-warning-modal-actions">
            <button
              className="file-warning-modal-button proceed-button"
              onClick={() => {
                setProceedWithoutFile(true);
                setShowFileWarningModal(false);
                handleAddOrUpdateDocument();
              }}
            >
              Yes, proceed
            </button>
            <button
              className="file-warning-modal-button cancel-button"
              onClick={() => setShowFileWarningModal(false)}
            >
              No, go back
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDocument;

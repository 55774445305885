const fetchGeneralDocuments = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/documents/general`);

    if (!response.ok) {
      throw new Error("Failed to fetch general documents");
    }

    const data = await response.json();

    return data.map(doc => ({
      document_id: doc.document_id,
      document_name: doc.document_name,
      file_path: doc.file_path, // Include file_path here
      section: doc.section
    }));
  } catch (error) {
    console.error("Error fetching general documents:", error);
    return []; // Return an empty array on error
  }
};

export default fetchGeneralDocuments;

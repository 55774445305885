import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/howto/entries`;

export const updateHowToEntry = async (entryId, updatedData) => {
  try {
    const formData = new FormData();

    // ✅ Ensure topic is an ID, not a string
    if (updatedData.topic) {
      const topicId = Number(updatedData.topic);
      if (isNaN(topicId)) {
        console.error("❌ Invalid topic ID:", updatedData.topic);
        throw new Error("Invalid topic ID");
      }
      formData.append("topic", topicId);
    }

    if (updatedData.file) {
      formData.append("file", updatedData.file);
    }

    const response = await axios.put(`${API_BASE_URL}/${entryId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (error) {
    console.error("❌ Error updating entry:", error.response?.data || error.message);
    throw error;
  }
};

// src/components/UserManagement/UserEditModal.js
import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./UserEditModal.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UserEditModal = ({ isOpen, onClose, editingUser, setEditingUser, handleUpdateUser }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="user-edit-modal-overlay" onClick={onClose}>
      <div className="user-edit-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="user-edit-close-button" onClick={onClose}>
          &times;
        </button>
        {editingUser && (
          <div className="user-edit-modal-form">
            <h3>Edit User</h3>
            <input
              type="text"
              value={editingUser.username}
              onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
              placeholder="Username"
              className="user-edit-input"
            />
            <input
              type="email"
              value={editingUser.email}
              onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
              placeholder="Email"
              className="user-edit-input"
            />
            <select
              value={editingUser.role}
              onChange={(e) => setEditingUser({ ...editingUser, role: e.target.value })}
              className="user-edit-select"
            >
              <option value="Admin">Admin</option>
              <option value="Manager">Manager</option>
              <option value="User">User</option>
            </select>
            <select
              value={editingUser.organization}
              onChange={(e) => setEditingUser({ ...editingUser, organization: e.target.value })}
              className="user-edit-select"
            >
              <option value="Organization 1">Organization 1</option>
              <option value="Organization 2">Organization 2</option>
            </select>
            <select
              value={editingUser.owning_team}
              onChange={(e) => setEditingUser({ ...editingUser, owning_team: e.target.value })}
              className="user-edit-select"
            >
              <option value="Team 1">Team 1</option>
              <option value="Team 2">Team 2</option>
            </select>
            <div className="password-field">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="New Password"
                value={editingUser.password}
                onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })}
                className="user-edit-input"
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)} className="password-toggle-icon">
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
            <button onClick={handleUpdateUser} className="user-edit-save-button">
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default UserEditModal;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./AddDocument.css";
import customers from "../../data/customers.json";
import Modal from "react-modal";
import {
  addSection,
  fetchSections as fetchSectionsAPI,
} from "../../services/sectionService"; // Import section service

const AddDocument = ({ documentType, setDocumentType }) => {
  const [documentName, setDocumentName] = useState("");
  const [section, setSection] = useState("");
  const [sections, setSections] = useState([]); // Initialize as an empty array
  const [newSection, setNewSection] = useState(""); // State for new section input
  const [showNewSectionInput, setShowNewSectionInput] = useState(false); // Toggle input visibility
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedFranchise, setSelectedFranchise] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [editingDocument, setEditingDocument] = useState(null);
  const [franchises, setFranchises] = useState([]);
  const [states, setStates] = useState([]);
  const [showFileWarningModal, setShowFileWarningModal] = useState(false);
  const [proceedWithoutFile, setProceedWithoutFile] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchSections = async () => {
    try {
      const data = await fetchSectionsAPI();
      setSections(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching sections:", error);
      setSections([]);
    }
  };

  const handleAddNewSection = async () => {
    if (!newSection.trim()) return;
    try {
      const addedSection = await addSection(newSection); // Use the API service to add a new section
      setSections((prevSections) => [...prevSections, addedSection]); // Add new section to list
      setSection(addedSection.section); // Set the new section as selected
      setNewSection(""); // Reset new section input
      setShowNewSectionInput(false); // Hide input
      toast.success("Section added successfully!");
    } catch (error) {
      console.error("Error adding new section:", error);
      toast.error("Failed to add section. Please try again.");
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/documents?documentType=${documentType}`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setDocuments(data);
      } else {
        setDocuments([]);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]);
    }
  };

  const fetchFranchises = async () => {
    try {
      const response = await fetch(`${apiUrl}/franchises`);
      if (!response.ok)
        throw new Error(`Error ${response.status}: ${response.statusText}`);

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        setFranchises(Array.isArray(data) ? data : []);
      } else {
        throw new Error("Received non-JSON response");
      }
    } catch (error) {
      console.error("Error fetching franchises:", error.message);
      setFranchises([]);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await fetch(`${apiUrl}/states`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setStates(data);
      } else {
        setStates([]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchSections(); // Fetch sections on mount
  }, []);

  useEffect(() => {
    if (documentType === "Franchise Document") {
      fetchFranchises();
    } else if (documentType === "State Document") {
      fetchStates();
    }
  }, [documentType]);

  const handleAddOrUpdateDocument = async () => {
    if (
      !documentName ||
      !section ||
      (documentType === "Additional Document" && !selectedCustomer) ||
      (documentType === "Franchise Document" && !selectedFranchise) ||
      (documentType === "State Document" && !selectedState)
    ) {
      toast.error("Please fill in all fields.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    if (!file && !proceedWithoutFile) {
      setShowFileWarningModal(true);
      return;
    }

    const formData = new FormData();
    formData.append("documentName", documentName);
    formData.append("documentType", documentType);
    formData.append("section", section);
    if (file) formData.append("file", file);

    if (documentType === "Additional Document") {
      const selectedCompany = customers.find(
        (c) => c.companyId === selectedCustomer
      );
      formData.append("companyId", selectedCustomer);
      formData.append(
        "companyName",
        selectedCompany ? selectedCompany.companyName : ""
      );
    } else if (documentType === "Franchise Document") {
      formData.append("franchiseId", selectedFranchise);
    } else if (documentType === "State Document") {
      formData.append("stateId", selectedState);
    }

    try {
      const method = editingDocument ? "PUT" : "POST";
      const url = editingDocument
        ? `${apiUrl}/documents/${editingDocument.document_id}`
        : `${apiUrl}/documents`;

      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (response.ok) {
        toast.success(
          `Document ${editingDocument ? "updated" : "added"} successfully!`,
          {
            position: "top-center",
            autoClose: 3000,
          }
        );
        fetchDocuments();
        resetForm();
      } else {
        toast.error("Error adding/updating document.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Server error. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const resetForm = () => {
    setDocumentName("");
    setSection("");
    setSelectedCustomer("");
    setSelectedFranchise("");
    setSelectedState("");
    setFile(null);
    setDocumentType("Select document type");
    setEditingDocument(null);
    setProceedWithoutFile(false);
  };

  const handleEditDocument = (document) => {
    setDocumentName(document.document_name);
    setSection(
      document.section ||
        document.state ||
        document.franchise ||
        document.company_name
    );
    setSelectedCustomer(document.company_id || "");
    setSelectedFranchise(document.franchise || "");
    setSelectedState(document.state || "");
    setEditingDocument(document);
  };

  const handleDeleteDocument = async (documentId) => {
    if (!window.confirm("Are you sure you want to delete this document?"))
      return;

    try {
      const response = await fetch(`${apiUrl}/documents/${documentId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        toast.success("Document deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        fetchDocuments();
      } else {
        toast.error("Error deleting document.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Server error. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleViewDocument = (document) => {
    toast.info(`Viewing document: ${document.document_name}`, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  Modal.setAppElement("#root");

  return (
    <div className="add-document">
      <h3>{editingDocument ? "Edit" : "Add"} Document</h3>
      <div className="add-document-form">
        <label htmlFor="document-type">Document Type</label>
        <select
          id="document-type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
        >
          <option value="">Select a document type...</option>
          <option value="State Document">
            State Document (State specific)
          </option>
          <option value="Franchise Document">
            Franchise Document (Franchise specific)
          </option>
          <option value="General Document">
            General Document (Accessible by all franchisees)
          </option>
          <option value="Additional Document">
            Additional Document (Specific to customer)
          </option>
        </select>

        <label htmlFor="document-name">Document Name</label>
        <input
          type="text"
          id="document-name"
          placeholder="Enter document name"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
        />

        <label htmlFor="section">Menu Section</label>
        <div className="section-select">
          <select
            id="section"
            className="section-select"
            value={section}
            onChange={(e) => setSection(e.target.value)}
          >
            <option value="">Select Section</option>
            {sections.map((sec, index) => (
              <option key={index} value={sec.section}>
                {sec.section}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => setShowNewSectionInput((prev) => !prev)}
            className="add-section-button"
          >
            +
          </button>
        </div>

        {showNewSectionInput && (
          <div className="add-new-section">
            <input
              type="text"
              value={newSection}
              onChange={(e) => setNewSection(e.target.value)}
              placeholder="Enter new section name"
            />
            <button onClick={handleAddNewSection}>Add Section</button>
          </div>
        )}

        {documentType === "Additional Document" && (
          <>
            <label htmlFor="customer">Select Customer</label>
            <select
              id="customer"
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e.target.value)}
            >
              <option value="">Select Customer</option>
              {customers.map((customer, index) => (
                <option key={index} value={customer.companyId}>
                  {customer.companyName}
                </option>
              ))}
            </select>
          </>
        )}

        {documentType === "Franchise Document" && (
          <>
            <label htmlFor="franchise">Select Franchise</label>
            <select
              id="franchise"
              value={selectedFranchise}
              onChange={(e) => setSelectedFranchise(e.target.value)}
            >
              <option value="">Select Franchise</option>
              {franchises.map((franchise, index) => (
                <option key={index} value={franchise.franchise_id}>
                  {franchise.franchise_name}
                </option>
              ))}
            </select>
          </>
        )}

        {documentType === "State Document" && (
          <>
            <label htmlFor="state">Select State</label>
            <select
              id="state"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select State</option>
              {states.map((state, index) => (
                <option key={index} value={state.state_id}>
                  {state.state_name}
                </option>
              ))}
            </select>
          </>
        )}

        <label htmlFor="file">Upload Document</label>
        <input
          type="file"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
        />

        <button
          className="add-document-button"
          onClick={handleAddOrUpdateDocument}
        >
          {editingDocument ? "Update Document" : "Add Document"}
        </button>
      </div>

      <Modal
        isOpen={showFileWarningModal}
        onRequestClose={() => setShowFileWarningModal(false)}
        contentLabel="File Warning"
        className="file-warning-modal"
        overlayClassName="file-warning-overlay"
      >
        <div className="file-warning-modal-content">
          <h2>No File Selected</h2>
          <p>
            You haven't selected a file to upload. Would you like to proceed
            without uploading a file?
          </p>
          <div className="file-warning-modal-actions">
            <button
              className="file-warning-modal-button proceed-button"
              onClick={() => {
                setProceedWithoutFile(true);
                setShowFileWarningModal(false);
                handleAddOrUpdateDocument();
              }}
            >
              Yes, proceed
            </button>
            <button
              className="file-warning-modal-button cancel-button"
              onClick={() => setShowFileWarningModal(false)}
            >
              No, go back
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDocument;

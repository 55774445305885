import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryptEmail } from "../../services/encryptEmail";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendForgotPasswordLink = async () => {
    if (email) {
      setLoading(true);
      try {
        const encryptedEmail = encryptEmail(email);

        if (!encryptedEmail) {
          toast.error("Failed to encrypt email. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          setLoading(false);
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password`,
          { email: encryptedEmail }
        );

        if (response.status === 200) {
          toast.success("Forgot password link sent to your email", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      } catch (error) {
        toast.error("Error sending forgot password link. Please try again.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
      setLoading(false);
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendForgotPasswordLink();
    }
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="forgot-password-page-container">
      <div className="forgot-password-logo-container">
        {/* Logo container */}
      </div>
      <div className="forgot-password-page-form">
        <h2 className="forgot-password-page-title">Forgot Password</h2>
        <input
          className="forgot-password-page-input"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          className="forgot-password-page-button"
          onClick={handleSendForgotPasswordLink}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Forgot Password Link"}
        </button>
        <p className="back-to-login-link" onClick={handleBackToLogin}>
          Back to Login
        </p>
      </div>
      {loading && <div className="loading-message">Please wait...</div>}
    </div>
  );
};

export default ForgotPassword;

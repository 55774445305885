// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
  }
  
  .dashboard-container {
    display: flex;
    flex: 1 1;
    height: calc(100vh - 60px);
  }
  
  @media (max-width: 768px) {
    .dashboard-container {
      flex-direction: column;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,SAAO;IACP,0BAA0B;EAC5B;;EAEA;IACE;MACE,sBAAsB;IACxB;EACF","sourcesContent":[".dashboard {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100vh;\r\n    overflow-y: hidden;\r\n  }\r\n  \r\n  .dashboard-container {\r\n    display: flex;\r\n    flex: 1;\r\n    height: calc(100vh - 60px);\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .dashboard-container {\r\n      flex-direction: column;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

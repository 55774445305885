import axios from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// fetchStateByCode.js
const fetchStateByCode = async (stateCodes) => {
  try {
    const codes = Array.isArray(stateCodes) ? stateCodes.join(",") : stateCodes;
    const response = await axios.get(`${baseURL}/states/by-code?code=${codes}`);
    
    if (Array.isArray(response.data)) {
      // Multiple states returned
      return response.data.map((state) => state.state_abbreviation);
    }
    
    // Single state returned
    return response.data.state_abbreviation;
  } catch (error) {
    console.error("Error fetching state(s) by code:", error.response?.data?.message || error.message);
    throw error;
  }
};

export default fetchStateByCode;



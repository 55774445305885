import React, { useState } from "react";
import "./EmployeeDocumentManager.css";

const EmployeeDocumentManager = () => {
  const [employeeData, setEmployeeData] = useState({
    ssn: "",
    division: "",
    department: "",
    legalName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    hourlyRate: "",
    employmentStatus: "",
    federalW4: "",
    stateW4: "",
    scheduleEarnings: "",
    earningsType: "",
    earningsAmount: "",
    scheduledDeductions: "",
    deductionType: "",
    deductionAmount: "",
    memos: "",
    memoType: "",
    memoAmount: "",
    hireDate: "",
    birthDate: "",
    terminationDate: "",
    timeOffPlan: "",
    currentTimeOff: "",
    bankingInfo: "",
    directDepositForm: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmployeeData({
      ...employeeData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Employee Data:", employeeData);
    // Add API integration or other logic here
  };

  return (
    <div className="employee-document-manager">
      <h2>Employee Demographics & Payroll Information</h2>
      <form className="employee-form" onSubmit={handleSubmit}>
        <fieldset>
          <div className="form-columns">
            {/* Column 1 */}
            <div className="form-column">
              <label>
                Social Security Number:
                <input
                  type="text"
                  name="ssn"
                  value={employeeData.ssn}
                  onChange={handleChange}
                />
              </label>
              <label>
              Division assignment (if applicable)
                <input
                  type="text"
                  name="division"
                  value={employeeData.division}
                  onChange={handleChange}
                />
              </label>
              <label>
                Department Assignment (if applicable)
                <input
                  type="text"
                  name="department"
                  value={employeeData.department}
                  onChange={handleChange}
                />
              </label>
              <label>
                Legal Name:
                <input
                  type="text"
                  name="legalName"
                  value={employeeData.legalName}
                  onChange={handleChange}
                />
              </label>
              <label>
                Address:
                <input
                  type="text"
                  name="address"
                  value={employeeData.address}
                  onChange={handleChange}
                />
              </label>
              <label>
                City:
                <input
                  type="text"
                  name="city"
                  value={employeeData.city}
                  onChange={handleChange}
                />
              </label>
              <label>
                State:
                <input
                  type="text"
                  name="state"
                  value={employeeData.state}
                  onChange={handleChange}
                />
              </label>
              <label>
                Date of Hire:
                <input
                  type="date"
                  name="hireDate"
                  value={employeeData.hireDate}
                  onChange={handleChange}
                />
              </label>
              <label>
                Birth Date:
                <input
                  type="date"
                  name="birthDate"
                  value={employeeData.birthDate}
                  onChange={handleChange}
                />
              </label>
              <label>
                Termination Date (if applicable)
                <input
                  type="date"
                  name="terminationDate"
                  value={employeeData.terminationDate}
                  onChange={handleChange}
                />
              </label>
            </div>

            {/* Column 2 */}
            <div className="form-column">
              <label>
                Zip:
                <input
                  type="text"
                  name="zip"
                  value={employeeData.zip}
                  onChange={handleChange}
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="tel"
                  name="phone"
                  value={employeeData.phone}
                  onChange={handleChange}
                />
              </label>
              <label>
                Email Address:
                <input
                  type="email"
                  name="email"
                  value={employeeData.email}
                  onChange={handleChange}
                />
              </label>
              <label>
                Hourly Rate or Salary:
                <input
                  type="text"
                  name="hourlyRate"
                  value={employeeData.hourlyRate}
                  onChange={handleChange}
                />
              </label>
              <label>
                Employment Status:
                <select
                  name="employmentStatus"
                  value={employeeData.employmentStatus}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                </select>
              </label>
              <label>
                Time Off Accrual Plan (if applicable)
                <input
                  type="text"
                  name="timeOffPlan"
                  value={employeeData.timeOffPlan}
                  onChange={handleChange}
                />
              </label>
              <label>
                Current Time Off Available:
                <input
                  type="text"
                  name="currentTimeOff"
                  value={employeeData.currentTimeOff}
                  onChange={handleChange}
                />
              </label>
              <label>
                Federal W4 Information:
                <button
                  type="button"
                  className="upload-button"
                  onClick={() => {
                    // Logic to handle file upload
                    document.getElementById("directDepositUpload").click();
                  }}
                >
                  Upload
                </button>
                <input
                  type="file"
                  id="directDepositUpload"
                  name="directDepositForm"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setEmployeeData({
                        ...employeeData,
                        directDepositForm: file.name, // Save file name or object to state
                      });
                    }
                  }}
                />
              </label>
              {employeeData.directDepositForm && (
                <span className="file-info">
                  Uploaded: {employeeData.directDepositForm}
                </span>
              )}

              <label>
                State W4 Information (if applicable)
                <button
                  type="button"
                  className="upload-button"
                  onClick={() => {
                    // Logic to handle file upload
                    document.getElementById("directDepositUpload").click();
                  }}
                >
                  Upload
                </button>
                <input
                  type="file"
                  id="directDepositUpload"
                  name="directDepositForm"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setEmployeeData({
                        ...employeeData,
                        directDepositForm: file.name, // Save file name or object to state
                      });
                    }
                  }}
                />
              </label>
              {employeeData.directDepositForm && (
                <span className="file-info">
                  Uploaded: {employeeData.directDepositForm}
                </span>
              )}

              <label>
                Employee banking info (if applicable)
                <button
                  type="button"
                  className="upload-button"
                  onClick={() => {
                    // Logic to handle file upload
                    document.getElementById("directDepositUpload").click();
                  }}
                >
                  Upload Signed Direct Deposit Form
                </button>
                <input
                  type="file"
                  id="directDepositUpload"
                  name="directDepositForm"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setEmployeeData({
                        ...employeeData,
                        directDepositForm: file.name, // Save file name or object to state
                      });
                    }
                  }}
                />
              </label>
              {employeeData.directDepositForm && (
                <span className="file-info">
                  Uploaded: {employeeData.directDepositForm}
                </span>
              )}
            </div>

            {/* Column 3 */}
            <div className="form-column">
              <label><strong>Schedule Earnings (if applicable)</strong></label>
              <label>
                Type of Earning – IE: Cell phone Reimbursement
                <input
                  type="text"
                  name="earningsType"
                  value={employeeData.earningsType}
                  onChange={handleChange}
                />
              </label>
              <label>
                Earnings amount per payroll
                <input
                  type="number"
                  name="earningsAmount"
                  value={employeeData.earningsAmount}
                  onChange={handleChange}
                />
              </label>
              <hr className="line-break" />
              <label><strong>Scheduled Deductions (if applicable)</strong></label>
              <label>
                Type of deduction – IE: Pretax Health, 401k
                <input
                  type="text"
                  name="deductionType"
                  value={employeeData.deductionType}
                  onChange={handleChange}
                />
              </label>
              <label>
                Deduction amount per payroll
                <input
                  type="number"
                  name="deductionAmount"
                  value={employeeData.deductionAmount}
                  onChange={handleChange}
                />
              </label>
              <hr className="line-break" />
              <label><strong>Memos (if applicable)</strong></label>
              <label>
                Type of memo – IE: ER 401k Match
                <input
                  type="text"
                  name="memoType"
                  value={employeeData.memoType}
                  onChange={handleChange}
                />
              </label>
              <label>
                Memo amount per payroll
                <input
                  type="number"
                  name="memoAmount"
                  value={employeeData.memoAmount}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
          <div className="employee-actions-area">
            <button className="upload-all" type="submit">Upload Documents With All Information</button>
            <button className="submit-form" type="submit">Submit Information</button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default EmployeeDocumentManager;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCompany: null,
};

const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
  },
});

export const { setSelectedCompany, clearSelectedCompany } =
  selectedCompanySlice.actions;

export default selectedCompanySlice.reducer;

// fetchSidebarData.js
import fetchStateDocuments from './fetchStateDocuments';
import fetchFranchiseDocuments from './fetchFranchiseDocuments';
import fetchGeneralDocuments from './fetchGeneralDocuments';
import fetchAdditionalDocuments from './fetchAdditionalDocuments';

const fetchSidebarData = async (franchiseId, stateId, companyId) => {
  try {
    // Fetch documents from different sources
    const stateDocuments = await fetchStateDocuments(stateId);
    const franchiseDocuments = await fetchFranchiseDocuments(franchiseId);
    const generalDocuments = await fetchGeneralDocuments();
    const additionalDocuments = await fetchAdditionalDocuments(companyId);

    // Combine documents into the sidebar structure, grouped by sections
    const combinedDocuments = [
      ...stateDocuments,
      ...franchiseDocuments,
      ...generalDocuments,
      ...additionalDocuments,
    ];

    // Group documents by their 'section' and include file_path and document_id
    const sections = combinedDocuments.reduce((acc, doc) => {
      const section = doc.section || 'General'; // Default to 'General' if no section
      if (!acc[section]) {
        acc[section] = { items: [] };
      }
      // Build the URL using file_path if it exists and encode it
      const documentUrl = doc.file_path ? encodeURI(`http://localhost:5000/uploads/${doc.file_path}`) : null;
      console.log('Document URL: ', documentUrl);

      acc[section].items.push({ 
        name: doc.document_name, 
        documentId: doc.document_id, 
        filePath: documentUrl // Pass the encoded URL to access the document
      });

      return acc;
    }, {});

    return sections; // Return the grouped data
  } catch (error) {
    console.error('Error fetching sidebar data:', error);
    return {};
  }
};

export default fetchSidebarData;

// src/services/createEsignature.js
import axios from "axios";

const createEsignature = async ({ hashed_email, signature }) => {
  const payload = {
    hashed_email,
    signature,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/docSignatureRoutes`,
      payload,
      {
        headers: { "Content-Type": "application/json" }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating e-signature:", error);
    throw error;
  }
};

export default createEsignature;

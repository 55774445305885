import React, { useState, useEffect } from "react";
import "./ViewDocuments.css";
import DocumentModal from "../DocumentModal/DocumentModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";
import { toast } from "react-toastify";

const ViewDocuments = ({ documentType, documents, setDocuments }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingDocument, setEditingDocument] = useState(null);
  const [newDocumentName, setNewDocumentName] = useState("");
  const [newDocumentFile, setNewDocumentFile] = useState(null);
  const [newCrmReference, setNewCrmReference] = useState(""); // CRM Reference state

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDocuments = async () => {
    if (!documentType || documentType === "Select document type") return;
    try {
      const response = await fetch(
        `${apiUrl}/documents?documentType=${documentType}`
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setDocuments(data);
      } else {
        setDocuments([]);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]);
    }
  };

  const handleViewDocument = (document) => {
    if (!document || !document.file_path) {
      toast.error("Document cannot be viewed. File path is missing.");
      return;
    }
    const documentUrl = `${apiUrl.replace("/api", "")}/uploads/${
      document.file_path
    }`;
    setSelectedDocumentUrl(documentUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocumentUrl("");
  };

  const openDeleteModal = (documentId) => {
    setDocumentToDelete(documentId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDocumentToDelete(null);
  };

  const confirmDeleteDocument = async () => {
    try {
      const response = await fetch(`${apiUrl}/documents/${documentToDelete}`, {
        method: "DELETE",
      });
      if (response.ok) {
        toast.success("Document deleted successfully!");
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.document_id !== documentToDelete)
        );
        fetchDocuments();
      } else {
        toast.error("Error deleting document.");
      }
    } catch (error) {
      toast.error("Server error. Please try again later.");
    } finally {
      closeDeleteModal();
    }
  };

  const openEditModal = (document) => {
    setEditingDocument(document);
    setNewDocumentName(document.document_name);
    setNewCrmReference(document.crm_reference || ""); // Populate CRM Reference
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditingDocument(null);
    setNewDocumentName("");
    setNewDocumentFile(null);
    setNewCrmReference(""); // Reset CRM Reference
  };

  const handleEditDocument = async () => {
    if (!editingDocument || !newDocumentName) {
      toast.error("Document name is required.");
      console.error("Validation Error: Document name is missing.");
      return;
    }

    const formData = new FormData();
    formData.append("document_name", newDocumentName);
    formData.append("crm_reference", newCrmReference || ""); // Include CRM Reference
    formData.append("description", editingDocument?.description || ""); // Include Description
    formData.append("purpose", editingDocument?.purpose || ""); // Include Purpose
    if (newDocumentFile) {
      formData.append("file", newDocumentFile);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const endpointUrl = `${apiUrl}/documents/${editingDocument.document_id}`;
      const response = await fetch(endpointUrl, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        toast.success("Document updated successfully!");
        fetchDocuments();
        closeEditModal();
      } else {
        const errorResponse = await response.text();
        console.error("Error updating document:", errorResponse);
        toast.error("Error updating document.");
      }
    } catch (error) {
      console.error("Server error during document update:", error);
      toast.error("Server error. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [documentType]);

  return (
    <div className="view-documents">
      <h3>View Documents</h3>
      <table className="documents-table">
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Last Updated</th>
            <th>View</th>
            <th>Edit</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {documents.length > 0 ? (
            documents.map((doc, index) => (
              <tr key={index}>
                <td>{doc.document_name}</td>
                <td>{new Date(doc.updated_at).toLocaleDateString()}</td>
                <td
                  className="table-icon"
                  onClick={() => handleViewDocument(doc)}
                >
                  <span className="material-symbols-outlined">visibility</span>
                </td>
                <td className="table-icon" onClick={() => openEditModal(doc)}>
                  <span className="material-symbols-outlined">edit</span>
                </td>
                <td
                  className="table-icon"
                  onClick={() => openDeleteModal(doc.document_id)}
                >
                  <span className="material-symbols-outlined">delete</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center" }}>
                No documents available for this category.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedDocumentUrl && (
        <DocumentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          documentUrl={selectedDocumentUrl}
        />
      )}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDeleteDocument}
      />
      {isEditModalOpen && (
        <div className="update-document-modal-overlay">
          <div className="update-document-modal">
            <h3>Edit Document</h3>
            <div className="update-document-modal-form-group">
              <label htmlFor="new-document-name">Document Name</label>
              <input
                type="text"
                id="new-document-name"
                value={newDocumentName}
                onChange={(e) => setNewDocumentName(e.target.value)}
              />
            </div>
            <div className="update-document-modal-form-group">
              <label htmlFor="new-crm-reference">CRM Reference</label>
              <input
                type="text"
                id="new-crm-reference"
                value={newCrmReference}
                onChange={(e) => setNewCrmReference(e.target.value)}
              />
            </div>
            <div className="update-document-modal-form-group">
              <label htmlFor="new-document-description">
                Document Description
              </label>
              <textarea
                id="new-document-description"
                value={editingDocument?.description || ""}
                onChange={(e) =>
                  setEditingDocument((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="update-document-modal-form-group">
              <label htmlFor="new-document-purpose">Document Purpose</label>
              <textarea
                id="new-document-purpose"
                value={editingDocument?.purpose || ""}
                onChange={(e) =>
                  setEditingDocument((prev) => ({
                    ...prev,
                    purpose: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="update-document-modal-form-group">
              <label htmlFor="new-document-file">
                Upload New File (Optional)
              </label>
              <input
                type="file"
                id="new-document-file"
                onChange={(e) => setNewDocumentFile(e.target.files[0])}
              />
            </div>
            <div className="update-document-modal-actions">
              <button onClick={handleEditDocument}>Save</button>
              <button onClick={closeEditModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewDocuments;

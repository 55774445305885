import React, { useEffect, useState, useCallback } from "react";
import fetchDocumentNames from "../../../services/fetchDocumentNames";
import "./Checklist.css";

const Checklist = ({
  selectedCompany,
  combinedReferences = [],
  receivedReferences = [],
}) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [checklist, setChecklist] = useState([]);

  /**
   * Fetch documents and generate checklist.
   */
  const fetchAndSetDocuments = useCallback(async () => {
    if (!selectedCompany) {
      console.warn("No selected company. Skipping fetch.");
      return;
    }

    if (!combinedReferences || combinedReferences.length === 0) {
      console.warn("Combined references are empty. Skipping fetch.");
      return;
    }

    setIsLoadingDocuments(true);

    try {
      const fetchedDocuments = await fetchDocumentNames(combinedReferences);

      console.log("Fetched documents:", fetchedDocuments);

      const generatedChecklist = combinedReferences.map((reference) => {
        const matchingDocument = fetchedDocuments.find(
          (doc) => doc.crm_reference === reference
        );

        const documentName =
          matchingDocument?.document_name || reference.replace(/_/g, " ");

        return {
          documentName,
          status: receivedReferences.includes(reference)
            ? "Uploaded"
            : "Incomplete",
        };
      });

      console.log("Generated checklist:", generatedChecklist);
      setChecklist(generatedChecklist);
    } catch (error) {
      console.error("Error fetching document names:", error.message);
      setChecklist([]);
    } finally {
      setIsLoadingDocuments(false);
    }
  }, [selectedCompany, combinedReferences, receivedReferences]);

  useEffect(() => {
    fetchAndSetDocuments();
  }, [fetchAndSetDocuments]);

  return (
    <>
      <h4>Documents Checklist</h4>
      {isLoadingDocuments ? (
        <p>Loading documents...</p>
      ) : (
        <>
          {checklist.length === 0 ? (
            <p>No documents to display for the selected company.</p>
          ) : (
            <table className="recent-uploads-table">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {checklist.map((document, idx) => (
                  <tr key={idx}>
                    <td>{document.documentName || "Unnamed Document"}</td>
                    <td>
                      {document.status === "Uploaded" ? (
                        <span className="status-complete">&#10004;</span>
                      ) : (
                        <span className="status-incomplete">Incomplete</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default Checklist;

import React, { useState, useEffect } from "react";
import { fetchHowToList, uploadHowToEntry } from "../../services/howToService";
import { fetchHowToTopics, addNewTopic } from "../../services/fetchHowToTopics";
import FilePreviewModal from "../FilePreviewModal/FilePreviewModal";
import { deleteHowToEntry } from "../../services/deleteHowToEntry";
import { updateHowToEntry } from "../../services/updateHowToEntry";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import "./HowToManager.css";

const HowToManager = () => {
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [file, setFile] = useState(null);
  const [howToList, setHowToList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTopic, setNewTopic] = useState("");

  // ✅ Modal state for file preview
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState({ url: "", type: "" });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editEntry, setEditEntry] = useState({ id: "", topic: "", file: null });

  const userId = "12345"; // Placeholder, replace with actual user ID from auth context

  // Fetch topics and How-To list on mount
  useEffect(() => {
    fetchHowToTopics()
      .then((data) => {
        setTopics(data);
      })
      .catch((error) => console.error("Error fetching How-To topics:", error));

    fetchHowToList()
      .then((data) => {
        setHowToList(data);
      })
      .catch((error) => console.error("Error fetching How-To list:", error));
  }, []);

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedTopic || !file) {
      alert("Please select a topic and upload a file.");
      return;
    }

    try {
      await uploadHowToEntry(Number(selectedTopic), file, userId);

      // Reset file input
      setFile(null);
      document.getElementById("fileInput").value = "";
      const updatedList = await fetchHowToList();
      setHowToList(updatedList);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Upload failed. Please try again.");
    }
  };

  // Handle adding a new topic
  const handleAddTopic = async () => {
    if (!newTopic.trim()) {
      alert("Topic name cannot be empty.");
      return;
    }

    try {
      await addNewTopic(newTopic);
      const updatedTopics = await fetchHowToTopics();
      setTopics(updatedTopics);
      setSelectedTopic(
        updatedTopics.find((t) => t.title === newTopic)?.id || ""
      );

      // Close modal
      setNewTopic("");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Failed to add topic:", error);
    }
  };

  // Open file preview modal
  const inferFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const videoExtensions = ["mp4", "webm", "ogg"];
    const pdfExtensions = ["pdf"];

    if (imageExtensions.includes(extension)) return "image";
    if (videoExtensions.includes(extension)) return "video";
    if (pdfExtensions.includes(extension)) return "pdf";
    return "unknown";
  };

  const openPreviewModal = (fileUrl, fileName, fileType) => {
    if (!fileUrl) {
      console.warn("⚠ No file URL found!");
      return;
    }

    let correctedFileUrl = fileUrl.replace("/api", "");

    if (!correctedFileUrl.startsWith("http")) {
      correctedFileUrl = `${process.env.REACT_APP_STATIC_API_BASE_URL}${correctedFileUrl}`;
    }
    const inferredType = fileType || inferFileType(fileName);

    setPreviewFile({ url: correctedFileUrl, type: inferredType });
    setPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setPreviewModalOpen(false);
  };

  const handleDeleteEntry = async (entryId) => {
    if (!window.confirm("Are you sure you want to delete this entry?")) return;

    try {
      const response = await deleteHowToEntry(entryId);

      // Refresh list after deletion
      const updatedList = await fetchHowToList();
      setHowToList(updatedList);
    } catch (error) {
      console.error("Failed to delete entry:", error);
      alert("Failed to delete entry. Please try again.");
    }
  };

  const handleEditEntry = (entryId) => {
    const entry = howToList.find((item) => item.id === entryId);
    if (!entry) return;

    const topicObject = topics.find((topic) => topic.title === entry.topic);
    const topicId = topicObject ? topicObject.id : "";

    setEditEntry({ id: entry.id, topic: topicId, file: null });
    setIsEditModalOpen(true);
  };

  const handleSaveEditEntry = async () => {
    if (!editEntry.topic) {
      alert("Please select a topic.");
      return;
    }

    try {
      const updatedData = {
        topic: editEntry.topic,
        file: editEntry.file || null,
      };
      await updateHowToEntry(editEntry.id, updatedData);
      setIsEditModalOpen(false);
      const updatedList = await fetchHowToList();
      setHowToList(updatedList);
    } catch (error) {
      console.error("Failed to update entry:", error);
      alert("Failed to update entry. Please try again.");
    }
  };

  return (
    <div className="howto-manager">
      <h2 className="page-header-title">How-To Manager</h2>
      <form onSubmit={handleSubmit} className="howto-form">
        <label>Topic:</label>
        <div className="topic-container">
          <select
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(Number(e.target.value))}
            required
          >
            <option value="">Select a topic</option>
            {topics.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.title}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="new-topic-btn"
          >
            + New Topic
          </button>
        </div>

        <label className="upload-form-label">Upload File:</label>
        <input
          type="file"
          id="fileInput"
          accept="image/*, video/*, .pdf"
          onChange={handleFileChange}
        />
        <p className="upload-form-paragraph">Accepted formats: PDF, PNG, JPG, JPEG, MP4, BMP, SVG, WEBM, OGG</p>
        <p className="upload-form-paragraph">Max File Size: 100MB</p>
        <button type="submit">Upload</button>
      </form>

      <h3 className="existing-topics-heading">Uploaded How-To Topics</h3>
      <table>
        <thead>
          <tr>
            <th>Topic</th>
            <th>Uploaded Date</th>
            <th>File</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {howToList.map((item) => (
            <tr key={item.id}>
              <td>{item.topic}</td>
              <td>{new Date(item.created_at).toLocaleDateString()}</td>
              <td>
                <AiOutlineEye
                  className="icon-btn view-icon"
                  onClick={() => {
                    if (!item.fileUrl) {
                      console.warn("⚠ No file URL found for:", item);
                      return;
                    }
                    openPreviewModal(
                      `${process.env.REACT_APP_API_BASE_URL}${item.fileUrl}`,
                      item.file_name,
                      item.fileType
                    );
                  }}
                  title="View File"
                />
              </td>
              <td>
                <AiOutlineEdit
                  className="icon-btn edit-icon"
                  onClick={() => handleEditEntry(item.id)}
                  title="Edit Entry"
                />
              </td>
              <td>
                <AiOutlineDelete
                  className="icon-btn delete-icon"
                  onClick={() => handleDeleteEntry(item.id)}
                  title="Delete Entry"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isEditModalOpen && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <h3>Edit How-To Entry</h3>
            <label>Topic:</label>
            <select
              value={editEntry.topic}
              onChange={(e) =>
                setEditEntry((prev) => ({
                  ...prev,
                  topic: Number(e.target.value),
                }))
              }
            >
              {topics.map((topic) => (
                <option key={topic.id} value={topic.id}>
                  {topic.title}
                </option>
              ))}
            </select>

            <label>Upload New File (Optional):</label>
            <input
              type="file"
              onChange={(e) =>
                setEditEntry((prev) => ({ ...prev, file: e.target.files[0] }))
              }
            />

            <div className="edit-modal-buttons">
              <button className="save-btn" onClick={handleSaveEditEntry}>
                Save
              </button>
              <button
                className="cancel-btn"
                onClick={() => setIsEditModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* New Topic Modal */}
      {isModalOpen && (
        <div className="how-to-topics-modal">
          <div className="how-to-topics-modal-content">
            <h3>Create New Topic</h3>
            <input
              type="text"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter new topic"
            />
            <div className="how-to-topics-modal-buttons">
              <button className="how-to-add-button" onClick={handleAddTopic}>
                Add
              </button>
              <button
                className="how-to-cancel-button"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ✅ Always Render Modal but Hide When Closed */}
      <FilePreviewModal
        isOpen={previewModalOpen}
        fileUrl={previewFile.url}
        fileType={previewFile.type}
        onClose={closePreviewModal}
      />
    </div>
  );
};

export default HowToManager;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Fira Sans", sans-serif;
}

h1, h2, h3, h3, h4, h5, h6{
  color: #2c3e50;
}

/* Toast Close Button */
.Toastify__close-button {
    color: #ffffff !important;
    opacity: 1 !important;
  }
  
  .Toastify__close-button > svg {
    fill: #ffffff !important;
    width: 16px !important; /* Adjust icon size as needed */
    height: 16px !important;
  }
  
  /* Progress Bar Styles */
  .Toastify__progress-bar {
    background: #4c8c40 !important; /* Success color */
  }
  
  .Toastify__toast--error .Toastify__progress-bar {
    background: #f44336 !important; /* Error color */
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,oCAAoC;AACxC;;AAEA;EACE,cAAc;AAChB;;AAEA,uBAAuB;AACvB;IACI,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,wBAAwB;IACxB,sBAAsB,EAAE,+BAA+B;IACvD,uBAAuB;EACzB;;EAEA,wBAAwB;EACxB;IACE,8BAA8B,EAAE,kBAAkB;EACpD;;EAEA;IACE,8BAA8B,EAAE,gBAAgB;EAClD","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\r\n\r\n*{\r\n    padding: 0;\r\n    margin: 0;\r\n    box-sizing: border-box;\r\n    font-family: \"Fira Sans\", sans-serif;\r\n}\r\n\r\nh1, h2, h3, h3, h4, h5, h6{\r\n  color: #2c3e50;\r\n}\r\n\r\n/* Toast Close Button */\r\n.Toastify__close-button {\r\n    color: #ffffff !important;\r\n    opacity: 1 !important;\r\n  }\r\n  \r\n  .Toastify__close-button > svg {\r\n    fill: #ffffff !important;\r\n    width: 16px !important; /* Adjust icon size as needed */\r\n    height: 16px !important;\r\n  }\r\n  \r\n  /* Progress Bar Styles */\r\n  .Toastify__progress-bar {\r\n    background: #4c8c40 !important; /* Success color */\r\n  }\r\n  \r\n  .Toastify__toast--error .Toastify__progress-bar {\r\n    background: #f44336 !important; /* Error color */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const fetchFranchiseName = async (franchiseId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/franchises/${franchiseId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch franchise name');
      }
      const data = await response.json();
      return data.franchise_name; // Return franchise_name from response
    } catch (error) {
      console.error('Error fetching franchise name:', error);
      return null; // Return null in case of error
    }
  };
  
  export default fetchFranchiseName;
  
// src/redux/slices/franchiseSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  franchiseName: null, // Initially, no franchise name is stored
  loading: false,
  error: null,
};

const franchiseSlice = createSlice({
  name: "franchise",
  initialState,
  reducers: {
    setFranchiseName(state, action) {
      state.franchiseName = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setFranchiseName, setLoading, setError } = franchiseSlice.actions;

export default franchiseSlice.reducer;

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainContent from "../../components/MainContent/MainContent";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";
import { fetchDealsByEmail } from "../../services/fetchDealsService";
import { fetchTeamDeals } from "../../services/fetchTeamDeals";
import "./Dashboard.css";

const Dashboard = () => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInitialLogin, setIsInitialLogin] = useState(true);

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        if (user && user.role === "account manager" && user.owning_team) {
          // Fetch team deals for account manager
          data = await fetchTeamDeals(user.owning_team);
        } else if (user && user.email) {
          // Fetch deals by email for regular user
          data = await fetchDealsByEmail(user.email);
        }

        if (data) {
          // Map the data to a consistent structure for both cases
          const dealDetails = data.map((deal) => ({
            id: deal.new_dealid,
            name: deal.new_name || "No Name",
            total_employees: deal.hc_employeecount,
            stateId: deal.hc_operatingstate,
            franchiseId: deal._hc_franchise_team_value,
            raw: deal, // Full deal object for any other data needed
          }));
          setDeals(dealDetails);

          // Set stateId based on the first deal if available
          if (dealDetails[0]?.stateId) {
            setStateId(dealDetails[0].stateId);
          }
        }
      } catch (error) {
        setError("Failed to fetch deals.");
        console.error("Error fetching deals:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    if (!company) {
      setStateId(null);
      return;
    }
    setStateId(company.stateId); // Update stateId in Dashboard component
  };

  const clearSearch = () => {
    setSearchTerm("");
    setShowDropdown(false);
    handleCompanyChange(null);
  };

  const handleLoadingComplete = () => {
    setIsInitialLogin(false);
  };

  if ((loading || isInitialLogin) && isAuthenticated) {
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} />;
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="dashboard">
      <Navbar />
      <div className="dashboard-container">
        <Sidebar
          stateId={stateId}
          franchiseId={selectedCompany?.franchiseId ?? null}
          companyId={selectedCompany?.id ?? null}
        />
        <MainContent
          deals={deals} // Pass all deals to MainContent
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange}
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          stateId={stateId}
          setStateId={setStateId}
        />
      </div>
    </div>
  );
};

export default Dashboard;

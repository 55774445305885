import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    data: {}, // Existing data structure
    loading: false,
    error: null,
    requiredReferences: [], // Add these
    notApplicableReferences: [], // Add these
    receivedReferences: [], // Add these
  },
  reducers: {
    setSidebarData: (state, action) => {
      state.data = action.payload;
      state.error = null;
      // console.log('Sidebar data updated:', action.payload);
    },
    setSidebarLoading: (state, action) => {
      state.loading = action.payload;
      // console.log('Sidebar loading:', action.payload);
    },
    setSidebarError: (state, action) => {
      state.error = action.payload;
      console.error('Sidebar error:', action.payload);
    },
    setCRMReferences: (state, action) => {
      const { requiredReferences, notApplicableReferences, receivedReferences } = action.payload;
      state.requiredReferences = requiredReferences;
      state.notApplicableReferences = notApplicableReferences;
      state.receivedReferences = receivedReferences;
      // console.log('CRM references updated:', action.payload);
    },
  },
});

export const { 
  setSidebarData, 
  setSidebarLoading, 
  setSidebarError, 
  setCRMReferences, 
} = sidebarSlice.actions;

export default sidebarSlice.reducer;

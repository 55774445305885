// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/logo-dark.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/LoadingScreen/LoadingScreen.css */
.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7; /* Background color */
  }
  
  .logo-container {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .loading-logo {
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(1); /* Show the logo in white */
  }
  
  .logo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Full-color logo path */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: clip-path 0.2s ease;
  }
  
  .loading-text {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoadingScreen/LoadingScreen.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB,EAAE,qBAAqB;EAClD;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,+BAA+B,EAAE,2BAA2B;EAC9D;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yDAA0D,EAAE,yBAAyB;IACrF,wBAAwB;IACxB,2BAA2B;IAC3B,4BAA4B;IAC5B,+BAA+B;EACjC;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;EAClB","sourcesContent":["/* src/components/LoadingScreen/LoadingScreen.css */\r\n.loading-screen {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    background-color: #f7f7f7; /* Background color */\r\n  }\r\n  \r\n  .logo-container {\r\n    position: relative;\r\n    width: 150px;\r\n    height: 150px;\r\n  }\r\n  \r\n  .loading-logo {\r\n    width: 100%;\r\n    height: 100%;\r\n    filter: brightness(0) invert(1); /* Show the logo in white */\r\n  }\r\n  \r\n  .logo-overlay {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-image: url(\"../../assets/images/logo-dark.png\"); /* Full-color logo path */\r\n    background-size: contain;\r\n    background-position: center;\r\n    background-repeat: no-repeat;\r\n    transition: clip-path 0.2s ease;\r\n  }\r\n  \r\n  .loading-text {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    color: #333;\r\n    margin-top: 15px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import updateDocumentStatus from "../../services/updateDocumentStatus";
import "./UploadDocumentModal.css";

const UploadDocumentModal = ({
  isOpen,
  onClose,
  dealId,
  crmReference,
  documentName,
  onUploadSuccess,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const franchiseName = useSelector((state) => state.franchise.franchiseName);
  const companyName = useSelector(
    (state) => state.selectedCompany.selectedCompany?.name
  );

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleConfirm = async () => {
    if (selectedFiles.length === 0) {
      toast.warn("Please select at least one file to upload.");
      return;
    }
    if (!franchiseName) {
      toast.warn("Franchise name is required.");
      return;
    }
    if (!companyName) {
      toast.warn("Company name is required.");
      return;
    }

    try {
      setIsUploading(true);

      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        const originalExtension = file.name.split(".").pop();
        const newFileName = documentName?.includes(".")
          ? documentName
          : `${documentName}.${originalExtension}`;

        // Handle renaming logic
        const renamedFile = new File([file], newFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });

        formData.append(isBulkUpload ? "files" : "file", renamedFile);
      });

      formData.append("dealId", dealId);
      formData.append("locationName", companyName);
      formData.append("companyName", companyName);

      // Select appropriate endpoint based on upload type
      const endpoint = isBulkUpload
        ? `${process.env.REACT_APP_API_BASE_URL}/sharepoint-documents/upload-multiple`
        : `${process.env.REACT_APP_API_BASE_URL}/sharepoint-documents/upload`;

      const response = await axios.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        if (!isBulkUpload && crmReference) {
          await updateDocumentStatus(dealId, crmReference, "122990103");
        }
        toast.success("Files uploaded successfully!");
        setSelectedFiles([]);
        onClose();

        if (onUploadSuccess) {
          onUploadSuccess();
        }
      } else {
        throw new Error("File upload failed.");
      }
    } catch (error) {
      console.error("Error during upload or status update:", error.message);
      toast.error("Failed to upload files or update status.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    onClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="upload-modal-overlay">
      <div className="upload-modal-content">
        <button
          className="upload-close-button"
          onClick={onClose}
          disabled={isUploading}
        >
          &times;
        </button>
        <h3>Upload Document</h3>
        <div className="upload-options">
          <label>
            <input
              type="radio"
              name="uploadType"
              value="single"
              checked={!isBulkUpload}
              onChange={() => setIsBulkUpload(false)}
            />
            Single Document
          </label>
          <label>
            <input
              type="radio"
              name="uploadType"
              value="bulk"
              checked={isBulkUpload}
              onChange={() => setIsBulkUpload(true)}
            />
            Multi-Documents
          </label>
        </div>
        <div className="upload-modal-body">
          {/* Hidden File Input */}
          <input
            type="file"
            id="fileUploadInput"
            onChange={handleFileChange}
            multiple={isBulkUpload}
            className="upload-document-file-dialog"
          />

          {/* Custom Button to Trigger File Input */}
          <label htmlFor="fileUploadInput" className="upload-document-button">
            Choose File(s)
          </label>

          {selectedFiles.length > 0 && (
            <p className="uploaded-docs-text">
              {selectedFiles.length} file(s) selected for upload.
            </p>
          )}
        </div>

        <div className="upload-modal-actions">
          <button
            onClick={handleConfirm}
            className="upload-confirm-button"
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Confirm Upload"}
          </button>
          <button
            onClick={handleCancel}
            className="upload-cancel-button"
            disabled={isUploading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default UploadDocumentModal;

// DocumentActions.js
import React, { useState, useEffect } from "react";
import DocumentModal from "../DocumentModal/DocumentModal";
import ESignModal from "../TempModal/TempModal";
import UploadDocumentModal from "../UploadDocumentModal/UploadDocumentModal";
import * as pdfjsLib from "pdfjs-dist/webpack";
import "./DocumentActions.css";

const DocumentActions = ({ documentName, filePath }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isESignModalOpen, setIsESignModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const generateThumbnail = async () => {
      if (filePath) {
        try {
          console.log("Attempting to generate thumbnail for:", filePath);
          const loadingTask = pdfjsLib.getDocument(filePath);
          const pdf = await loadingTask.promise;
          const page = await pdf.getPage(1);
          const scale = 0.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          setThumbnail(canvas.toDataURL());
        } catch (error) {
          console.error("Error generating thumbnail:", error);
        }
      } else {
        console.warn("No filePath provided for generating thumbnail.");
        setThumbnail(null);
      }
    };

    generateThumbnail();
  }, [filePath]);

  const handleViewDocument = () => {
    console.log("Opening Document Modal for:", filePath);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleESignDocument = () => {
    // Disabled for now, no action needed
  };

  const handleUploadSingle = () => {
    setIsBulkUpload(false);
    setIsUploadModalOpen(true);
  };

  const handleUploadBulk = () => {
    setIsBulkUpload(true);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleConfirmUpload = async (files, tags) => {
    if (files && files.length > 0) {
      try {
        const formData = new FormData();
        formData.append("documentName", files[0].name);
        formData.append("locationName", "exampleLocation");
        formData.append("dealId", "exampleDealId");
        formData.append("file", files[0]);
        formData.append("tag", tags[0]);

        const response = await fetch(
          "http://localhost:5000/api/sharepoint-documents/upload",
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();
        if (response.ok) {
          alert("File uploaded successfully!");
        } else {
          console.error("Error uploading file:", result.message);
          alert("Error uploading file: " + result.message);
        }
      } catch (error) {
        console.error("Error in file upload process:", error);
        alert("Failed to upload file. Please try again.");
      }
    } else {
      alert("Please select a file to upload.");
    }
    setIsUploadModalOpen(false);
  };

  return (
    <div className="document-actions">
      <div className="action-buttons">
        <h3>{documentName}</h3>
        <button
          onClick={handleViewDocument}
          disabled={!filePath}
          className={!filePath ? "disabled" : ""}
        >
          View Document
        </button>
        <button onClick={handleUploadSingle}>Upload Single</button>
        <button onClick={handleUploadBulk}>Upload Bulk</button>
        <button
          onClick={handleESignDocument}
          disabled={true} // Disable for now until E-sign module is available
          className="disabled"
        >
          E-sign Document
        </button>
      </div>
      <div className="document-thumbnail-container">
        <h3>Document Thumbnail</h3>
        <div className="document-thumbnail">
          {filePath ? (
            thumbnail ? (
              <img src={thumbnail} alt="PDF Thumbnail" />
            ) : (
              <div className="thumbnail-placeholder">Loading...</div>
            )
          ) : (
            <div className="no-thumbnail-placeholder">
              No thumbnail available
            </div>
          )}
        </div>
      </div>
      <div className="document-details">
        <h3>Document Information</h3>
        <p>
          <strong>Document Description:</strong> Description of the document.
        </p>
        <p>
          <strong>Document Purpose:</strong> Purpose of the document.
        </p>
      </div>

      <DocumentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        documentUrl={filePath}
      />

      <ESignModal
        isOpen={isESignModalOpen}
        onClose={() => setIsESignModalOpen(false)}
        documentUrl={filePath}
      />

      <UploadDocumentModal
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onConfirm={handleConfirmUpload}
        isBulkUpload={isBulkUpload}
      />
    </div>
  );
};

export default DocumentActions;

const fetchCrmReference = async (documentName) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
    try {
      // Use the correct endpoint and query parameter
      const response = await fetch(`${apiUrl}/references/crm-reference?documentName=${encodeURIComponent(documentName)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      // Check if the response is successful
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch CRM reference.");
      }
  
      // Parse the JSON response and return the CRM reference
      const data = await response.json();
      return data.crmReference; // Assumes API returns { crmReference: "value" }
    } catch (error) {
      console.error("Error fetching CRM reference:", error);
      throw error;
    }
  };
  
  export default fetchCrmReference;
  
import { configureStore } from '@reduxjs/toolkit';
import activeComponentReducer from './slices/activeComponentSlice';
import organizationReducer from './slices/organizationSlice';
import authReducer from './slices/authSlice';
import franchiseReducer from './slices/franchiseSlice';
import selectedCompanyReducer from './slices/selectedCompanySlice';
import dealReducer from './slices/dealSlice';
import sidebarReducer from './slices/sidebarSlice';
import checklistReducer from "./slices/checklistSlice";

export const store = configureStore({
  reducer: {
    activeComponent: activeComponentReducer,
    organization: organizationReducer,
    auth: authReducer,
    franchise: franchiseReducer,
    selectedCompany: selectedCompanyReducer,
    deal: dealReducer,
    sidebar: sidebarReducer,
    checklist: checklistReducer,
  },
});

export default store;

// DocumentViewer.js
import React from 'react';
import { useSelector } from 'react-redux';
import DocumentActions from './DocumentActions';
import UploadedDocumentsTable from './UploadedDocumentsTable';
import dummyData from '../../data/dummyData.json';
import './DocumentViewer.css';

const DocumentViewer = () => {
  // Get selected organization, document name, and file path from Redux
  const selectedOrganization = useSelector((state) => state.organization.selectedOrganization);
  const documentName = useSelector((state) => state.activeComponent.documentName);
  const filePath = useSelector((state) => state.activeComponent.filePath); // Fetch filePath from Redux state
  const relevantDocuments = dummyData[selectedOrganization]?.[documentName] || [];

  return (
    <div className="document-viewer">
      <DocumentActions documentName={documentName} filePath={filePath} /> {/* Pass filePath as a prop */}
      <UploadedDocumentsTable documents={relevantDocuments} />
    </div>
  );
};

export default DocumentViewer;

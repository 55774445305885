import React, { useState } from "react";
import { useSelector } from "react-redux";
import CompanyDashboard from "../CompanyDashboard/CompanyDashboard";
import DocumentViewer from "../DocumentViewer/DocumentViewer";
import DocumentManager from "../DocumentManager/DocumentManager";
import UserManager from "../UserManager/UserManager";
import "./MainContent.css";
import EmployeeDocumentManager from "../EmployeeDocumentManager/EmployeeDocumentManager";

const MainContent = ({
  deals,
  selectedCompany,
  handleCompanyChange,
  clearSearch,
  searchTerm,
  setSearchTerm,
  showDropdown,
  setShowDropdown,
  stateId,
  setStateId,
  setRequiredReferences,
  setReceivedReferences,
  requiredReferences,
  receivedReferences,
  progressPercentage,
}) => {
  const { activeComponent, documentName } = useSelector(
    (state) => state.activeComponent
  );
  const [crmReference, setCrmReference] = useState("");

  let content;
  switch (activeComponent) {
    case "companyDashboard":
      content = (
        <CompanyDashboard
          deals={deals}
          selectedCompany={selectedCompany}
          handleCompanyChange={handleCompanyChange}
          clearSearch={clearSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          stateId={stateId}
          setStateId={setStateId}
          setRequiredReferences={setRequiredReferences}
          setReceivedReferences={setReceivedReferences}
          requiredReferences={requiredReferences}
          receivedReferences={receivedReferences}
          progressPercentage={progressPercentage}
        />
      );
      break;
    case "documentViewer":
      content = (
        <DocumentViewer
          documentName={documentName}
          deals={deals}
          crmReference={crmReference}
        />
      );
      break;
    case "documentManager":
      content = <DocumentManager />;
      break;
    case "userManager":
      content = <UserManager />;
      break;
    case "employeeDocumentManager":
      content = <EmployeeDocumentManager />;
      break;
    default:
      content = (
        <CompanyDashboard
          deals={deals}
          setRequiredReferences={setRequiredReferences}
          setReceivedReferences={setReceivedReferences}
          requiredReferences={requiredReferences}
          receivedReferences={receivedReferences}
          progressPercentage={progressPercentage}
        />
      );
  }

  return <div className="main-content">{content}</div>;
};

export default MainContent;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import "./Navbar.css";
import logo from "../../assets/images/Journey-white-home-site-logo.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth.user?.role); // Access the user's role from Redux

  const handleLogout = () => {
    // Dispatch the logout action to update the Redux state
    dispatch(logout());

    // Clear any session data you may be using for authentication
    sessionStorage.clear();

    // Redirect based on the user's role
    if (userRole === "user") {
      navigate("/magic-link");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="navbar-wrapper">
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
      <div className="navbar-rest">
        <div className="icons-container">
          <div className="logout-container" onClick={handleLogout}>
            <span className="material-symbols-outlined">logout</span>
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

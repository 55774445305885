import axios from "axios";

const fetchSignature = async () => {
    const hashedEmail = sessionStorage.getItem("hashed_email");
    console.log('Fetching the signature...')

    if (!hashedEmail) {
        console.error("No hashed email found in session storage.");
        return null;
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/docSignatureRoutes/get`,
            { hashed_email: hashedEmail },
            { headers: { "Content-Type": "application/json" } }
        );

        if (response.data && response.data.signature) {
            console.log("Fetched signature successfully:", response.data.signature);
            return response.data.signature;
        } else {
            console.log("No signature found for this user.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching signature:", error);
        return null;
    }
};

export default fetchSignature;

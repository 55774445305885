import axios from "axios";

/**
 * Fetch document details by CRM reference.
 * @param {string} crmReference - The CRM reference of the document.
 * @returns {Promise<object>} The document details.
 */
const fetchDocumentDetails = async (crmReference) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/documents/details?crmReference=${crmReference}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching document details:", error);
    throw new Error("Could not fetch document details.");
  }
};

export default fetchDocumentDetails;

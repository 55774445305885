// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #ece9e6, #ffffff);
    font-family: 'Arial', sans-serif;
  }
  
  .not-found-title {
    font-size: 72px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .not-found-message {
    font-size: 24px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .not-found-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #193768;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .not-found-button:hover {
    background-color: #263f68;
    transform: translateY(-2px);
  }
  
  .not-found-button:active {
    background-color: #263f68;
    transform: translateY(0);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,qDAAqD;IACrD,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iDAAiD;EACnD;;EAEA;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;IACzB,wBAAwB;EAC1B","sourcesContent":[".not-found-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    background: linear-gradient(135deg, #ece9e6, #ffffff);\r\n    font-family: 'Arial', sans-serif;\r\n  }\r\n  \r\n  .not-found-title {\r\n    font-size: 72px;\r\n    margin-bottom: 20px;\r\n    color: #333;\r\n  }\r\n  \r\n  .not-found-message {\r\n    font-size: 24px;\r\n    margin-bottom: 20px;\r\n    color: #555;\r\n  }\r\n  \r\n  .not-found-button {\r\n    padding: 12px 20px;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    background-color: #193768;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s, transform 0.2s;\r\n  }\r\n  \r\n  .not-found-button:hover {\r\n    background-color: #263f68;\r\n    transform: translateY(-2px);\r\n  }\r\n  \r\n  .not-found-button:active {\r\n    background-color: #263f68;\r\n    transform: translateY(0);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setActiveComponent } from "../../../redux/slices/activeComponentSlice";
import fetchDocumentNames from "../../../services/fetchDocumentNames";
import "./Checklist.css";

const Checklist = ({ selectedCompany, combinedReferences = [], receivedReferences = [] }) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [checklist, setChecklist] = useState([]);
  const dispatch = useDispatch(); // ✅ Redux dispatch for navigation

  /**
   * Fetch documents and generate checklist.
   */
  const fetchAndSetDocuments = useCallback(async () => {
    if (!selectedCompany) {
      console.warn("No selected company. Skipping fetch.");
      return;
    }

    if (!combinedReferences || combinedReferences.length === 0) {
      console.warn("Combined references are empty. Skipping fetch.");
      return;
    }

    setIsLoadingDocuments(true);

    try {
      const fetchedDocuments = await fetchDocumentNames(combinedReferences);
      const generatedChecklist = combinedReferences.map((reference) => {
        const matchingDocument = fetchedDocuments.find(
          (doc) => doc.crm_reference === reference
        );
        const documentName = matchingDocument?.document_name || reference.replace(/_/g, " ");
        const filePath = matchingDocument?.file_path || "";

        return {
          documentName,
          filePath,
          crmReference: reference,
          status: receivedReferences.includes(reference) ? "Uploaded" : "Incomplete",
        };
      });

      setChecklist(generatedChecklist);
    } catch (error) {
      console.error("Error fetching document names:", error.message);
      setChecklist([]);
    } finally {
      setIsLoadingDocuments(false);
    }
  }, [selectedCompany, combinedReferences, receivedReferences]);

  useEffect(() => {
    fetchAndSetDocuments();
  }, [fetchAndSetDocuments]);

  /**
   * Handle clicking on a document row to open it in the `DocumentViewer`
   */
  const handleDocumentClick = async (document) => {
    // Ensure filePath does not contain /api prefix
    const cleanedFilePath = document.filePath ? document.filePath.replace("/api", "") : null;

    dispatch(
      setActiveComponent({
        component: "documentViewer",
        documentName: document.documentName,
        filePath: cleanedFilePath,
        crmReference: document.crmReference,
      })
    );
  
    setTimeout(() => {
    }, 300);
  };
  
  return (
    <>
      <h4>Documents Checklist</h4>
      {isLoadingDocuments ? (
        <p>Loading documents...</p>
      ) : (
        <>
          {checklist.length === 0 ? (
            <p>No documents to display for the selected company.</p>
          ) : (
            <table className="recent-uploads-table">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {checklist.map((document, idx) => (
                  <tr 
                    key={idx} 
                    className="clickable-row"
                    onClick={() => handleDocumentClick(document)}
                  >
                    <td>{document.documentName || "Unnamed Document"}</td>
                    <td>
                      {document.status === "Uploaded" ? (
                        <span className="status-complete">&#10004;</span>
                      ) : (
                        <span className="status-incomplete">Incomplete</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default Checklist;

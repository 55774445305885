import React, { useState, useEffect } from "react";
import { fetchHowToTopics } from "../../../services/fetchHowToTopics";
import { fetchHowToList } from "../../../services/howToService";
import "./HowToAccordion.css";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

/* 
  HowToAccordion displays topics as accordions and shows file previews for each entry.
  The file preview is determined by the fileType set on the entry.
  Supported file types:
    - "video": Renders a <video> tag.
    - "pdf": Renders an <iframe> for PDF preview.
    - "image": Renders an <img> tag.
*/
const HowToAccordion = () => {
  const [topics, setTopics] = useState([]);
  const [entries, setEntries] = useState([]);
  const [openTopic, setOpenTopic] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicData = await fetchHowToTopics();
        setTopics(topicData);

        const entryData = await fetchHowToList();
        setEntries(entryData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleAccordion = (topicId) => {
    setOpenTopic(openTopic === topicId ? null : topicId);
  };

  // Helper function to render the appropriate preview component
  const renderFilePreview = (entry) => {
    // Construct the complete URL to the file
    const fileUrl = `${process.env.REACT_APP_STATIC_API_BASE_URL}${entry.fileUrl}`;
    
    switch (entry.fileType) {
      case "video":
        return (
          <video controls className="preview-video">
            <source src={fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "pdf":
        return (
          <iframe
            src={fileUrl}
            className="preview-pdf"
            title="PDF Preview"
          />
        );
      case "image":
        return (
          <img
            src={fileUrl}
            alt={entry.file_name}
            className="preview-image"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="howto-accordion">
      <h2>How To Guides</h2>
      {topics.length === 0 ? (
        <p>No topics available.</p>
      ) : (
        topics.map((topic) => (
          <div key={topic.id} className="accordion-item">
            <button
              className="accordion-header"
              onClick={() => toggleAccordion(topic.id)}
            >
              {topic.title}
              {openTopic === topic.id ? (
                <AiOutlineUp className="icon" />
              ) : (
                <AiOutlineDown className="icon" />
              )}
            </button>
            {openTopic === topic.id && (
              <div className="accordion-content">
                {entries
                  .filter((entry) => entry.topic === topic.title)
                  .map((entry) => (
                    <div key={entry.id} className="file-entry">
                      {renderFilePreview(entry)}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default HowToAccordion;

import React, { useState, useEffect } from 'react';
import AddDocument from '../../components/AddDocument/AddDocument';
import ViewDocuments from '../../components/ViewDocuments/ViewDocuments';
import './DocumentManager.css';

const DocumentManager = () => {
  const [documentType, setDocumentType] = useState('Select document type');
  const [documents, setDocuments] = useState([]);
  const [newCrmReference, setNewCrmReference] = useState('');

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${apiUrl}/documents?documentType=${documentType}`);
      const data = await response.json();
      setDocuments(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
    }
  };

  useEffect(() => {
    if (documentType !== 'Select document type') {
      fetchDocuments();
    }
  }, [documentType]);

  return (
    <div className="document-manager">
      <h2 className="document-manager-title">Document Manager</h2>
      <AddDocument
        documentType={documentType}
        setDocumentType={setDocumentType}
        fetchDocuments={fetchDocuments}
        crmReference={newCrmReference}
        setCrmReference={setNewCrmReference}
      />
      <ViewDocuments
        documentType={documentType}
        documents={documents}
        setDocuments={setDocuments}
        crmReference={newCrmReference}
        setCrmReference={setNewCrmReference}
      />
    </div>
  );
};

export default DocumentManager;

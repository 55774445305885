import React from "react";
import ReactDOM from "react-dom";
import "./UserEditModal.css";

const UserEditModal = ({ isOpen, onClose, editingUser, setEditingUser, handleUpdateUser, franchisees }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="user-edit-modal-overlay" onClick={onClose}>
      <div className="user-edit-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="user-edit-close-button" onClick={onClose}>
          &times;
        </button>
        {editingUser && (
          <div className="user-edit-modal-form">
            <h3>Edit User</h3>
            <input
              type="text"
              value={editingUser.username}
              onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
              placeholder="Username"
              className="user-edit-input"
            />
            <input
              type="email"
              value={editingUser.email}
              onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
              placeholder="Email"
              className="user-edit-input"
            />
            <select
              value={editingUser.role}
              onChange={(e) => setEditingUser({ ...editingUser, role: e.target.value })}
              className="user-edit-select"
            >
              <option value="admin">Admin</option>
              <option value="account manager">Account Manager</option>
            </select>
            <select
              value={editingUser.owning_team}
              onChange={(e) => setEditingUser({ ...editingUser, owning_team: e.target.value })}
              className="user-edit-select"
            >
              <option value="">Select Owning Team</option>
              {franchisees.map((franchise) => (
                <option key={franchise.franchise_id} value={franchise.franchise_id}>
                  {franchise.franchise_name}
                </option>
              ))}
            </select>
            <button onClick={handleUpdateUser} className="user-edit-save-button">
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default UserEditModal;

import React, { useState, useEffect } from "react";
import { FaTrash, FaEye } from "react-icons/fa";
import DocumentModal from "../DocumentModal/DocumentModal";
import "./UploadedDocumentsTable.css";
import fetchUploadedDocuments from "../../services/fetchUploadedDocuments";
import deleteUploadedDocument from "../../services/deleteUploadedDocument";
import updateDocumentStatus from "../../services/updateDocumentStatus";
import axios from "axios"; // For making API calls to the backend
import { toast } from "react-toastify";

const UploadedDocumentsTable = ({
  dealId,
  companyName,
  documentName,
  refreshFlag,
  crmReference,
  franchiseName,
}) => {
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        setError("");

        // Fetch documents using the provided service
        const fetchedDocuments = await fetchUploadedDocuments(
          dealId,
          companyName
        );

        if (fetchedDocuments.success) {
          setDocuments(fetchedDocuments.data);
        } else {
          setError(fetchedDocuments.message || "Failed to fetch documents.");
        }
      } catch (err) {
        console.error("Error fetching documents:", err.message);
        setError("An error occurred while fetching documents.");
      } finally {
        setIsLoading(false);
      }
    };

    if (dealId && companyName) {
      fetchDocuments();
    }
  }, [dealId, companyName, refreshFlag]);

  const handleViewClick = async (doc) => {
    try {
      // Prepare the backend URL
      const viewUrl = `${process.env.REACT_APP_API_BASE_URL}/documents/view`;

      // Open a blank new window
      const width = 800;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;

      const newWindow = window.open(
        "",
        "_blank",
        `width=${width},height=${height},scrollbars=yes,resizable=yes,top=${top},left=${left}`
      );

      if (!newWindow) {
        alert("Popup blocked! Please allow popups for this website.");
        return;
      }

      // Use the downloadUrl directly to stream the document via the backend
      const params = new URLSearchParams({
        documentName: doc.name,
        downloadUrl: doc["@microsoft.graph.downloadUrl"],
        franchiseName,
        companyName,
        dealId,
      });

      // Redirect the new window to the backend URL with query parameters
      newWindow.location.href = `${viewUrl}?${params.toString()}`;
    } catch (error) {
      console.error("Error viewing document:", error.message);
      toast.error("An error occurred while trying to view the document.");
    }
  };

  const handleDeleteClick = async (docName) => {
    try {
      // Mark the document being deleted and trigger loading overlay
      setIsDeleting(true);

      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc.name === docName ? { ...doc, isDeleting: true } : doc
        )
      );

      // Call the backend to delete the document
      const result = await deleteUploadedDocument(
        franchiseName,
        companyName,
        docName,
        dealId
      );

      if (result.success) {
        const { message } = result;

        // Remove the deleted document directly from the table
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.name !== docName)
        );

        // Update CRM status after deletion
        if (crmReference) {
          await updateDocumentStatus(dealId, crmReference, "122990101");
          toast.success("Document status updated in CRM after deletion.");
        }

        // Show appropriate success toast
        if (message.includes("both primary and backup folders")) {
          toast.success(
            `Document "${docName}" was successfully deleted from both primary and backup folders.`
          );
        } else if (message.includes("primary folder")) {
          toast.info(
            `Document "${docName}" was deleted from the primary folder, but not found in the backup folder.`
          );
        } else if (message.includes("backup folder")) {
          toast.info(
            `Document "${docName}" was deleted from the backup folder, but not found in the primary folder.`
          );
        } else {
          toast.success(`Document "${docName}" has been deleted successfully.`);
        }
      } else {
        toast.error(
          result.message || "Failed to delete document. Please try again."
        );
      }
    } catch (err) {
      console.error("Error deleting document:", err.message);
      toast.error(
        "An unexpected error occurred while deleting the document. Please try again."
      );
    } finally {
      // Clear the `isDeleting` state for that document
      setIsDeleting(false);

      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc.name === docName ? { ...doc, isDeleting: false } : doc
        )
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDocumentUrl("");
  };

  const formatDate = (dateInput) => {
    try {
      const date = new Date(dateInput);
      return date.toLocaleDateString();
    } catch {
      return "Invalid date";
    }
  };

  const filteredDocuments = documents.filter((doc) =>
    doc.name.toLowerCase().includes(documentName.toLowerCase())
  );

  return (
    <div className="uploaded-documents-table">
      {error && <p className="error-message">{error}</p>}

      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Upload Date</th>
            <th>View Document</th>
            <th>Delete</th>
          </tr>
        </thead>
      </table>

      <div className="scrollable-tbody">
        {/* ✅ Loading Overlay for Table Refresh */}
        {isLoading && (
          <div className="overlay">
            <div className="loader"></div>
            <p>Refreshing documents...</p>
          </div>
        )}

        {isDeleting && (
          <div className="overlay">
            <div className="loader"></div>
            <p>Deleting document...</p>
          </div>
        )}

        <table>
          <tbody>
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc, idx) => {
                const { name, lastModifiedDateTime } = doc;

                return (
                  <tr key={idx}>
                    <td>{name || "Unnamed Document"}</td>
                    <td>
                      {lastModifiedDateTime
                        ? formatDate(lastModifiedDateTime)
                        : "N/A"}
                    </td>
                    <td>
                      <button
                        className="icon-button view-button"
                        onClick={() => handleViewClick(doc)}
                      >
                        <FaEye />
                      </button>
                    </td>
                    <td>
                      <button
                        className="icon-button delete-button"
                        onClick={() => handleDeleteClick(name)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="4"
                  style={{ textAlign: "center", padding: "1rem" }}
                >
                  No documents available for this category.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DocumentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        documentUrl={documentUrl}
      />
    </div>
  );
};

export default UploadedDocumentsTable;

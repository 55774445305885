// src/services/fetchTeamDeals.js
import axios from 'axios';

export const fetchTeamDeals = async (teamId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/team-deals?teamId=${teamId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching team deals:', error.response?.data?.message || error.message);
    throw new Error('Failed to fetch team deals');
  }
};

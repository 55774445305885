/**
 * @file sendApiKey.js
 * @description Service to communicate with the DMP backend to trigger sending
 *              the API key to the e-signature application.
 */

/**
 * Sends a payload to the DMP backend that triggers the forwarding of the API key.
 * @param {Object} payload - Data to send to the backend (e.g., document name, CRM reference, dealId, etc.).
 * @returns {Promise<Object>} - The response from the backend.
 */
const sendApiKey = async (payload = {}) => {
    try {
      // Make a POST request to the backend endpoint.
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/send-esign-api-key`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      // Parse the JSON response from the backend.
      const data = await response.json();
      console.log('Esign Data: ', data);
      return data;
    } catch (error) {
      // Propagate any error to be handled by the caller.
      throw error;
    }
  };
  
  export default sendApiKey;
  
// src/services/validateTokenService.js
import axios from 'axios';

export const validateMagicLinkToken = async (token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/validate-token`, { token });
    return response.data; // Assuming the backend returns the validated user info
  } catch (error) {
    console.error('Error validating token:', error);
    throw error; // Re-throw the error to be handled in the calling function
  }
};

// src/config/toastConfig.js
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Default toast options
const toastOptions = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progressClassName: "custom-progress-bar",
};

export const showSuccessToast = (message) => {
    toast.success(message, {
      ...toastOptions,
      style: { backgroundColor: "#4c8c40", color: "#ffffff" },
    });
  };
  
  export const showErrorToast = (message) => {
    toast.error(message, {
      ...toastOptions,
      style: { backgroundColor: "#193768", color: "#ffffff" },
    });
  };
  

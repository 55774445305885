const updateDocumentStatus = async (dealId, crmReference, newValue) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  try {
    // Prepare the payload
    const payload = {
      fieldToUpdate: crmReference, // Field to update in CRM
      newValue: newValue, // Dynamically set the new value
    };

    // Send data to backend
    const response = await fetch(`${apiUrl}/deals/${dealId}`, {
      method: "PATCH", // Use PATCH to match the backend
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const result = await response.json();
      console.error("Error updating document status:", result.message);
      throw new Error(result.message || "Failed to update document status.");
    }

    return true; // Indicate success
  } catch (error) {
    console.error("Error in updateDocumentStatus:", error);
    throw error; // Rethrow for the calling component to handle
  }
};

export default updateDocumentStatus;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-manager {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .document-manager-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/DocumentManager/DocumentManager.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB","sourcesContent":[".document-manager {\r\n    padding: 20px;\r\n    background-color: #fff;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .document-manager-title {\r\n    font-size: 24px;\r\n    margin-bottom: 20px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../../redux/slices/authSlice";
import { setActiveComponent } from "../../redux/slices/activeComponentSlice";
import { setSelectedOrganization } from "../../redux/slices/organizationSlice";
import { login } from "../../services/authService";
import { showSuccessToast, showErrorToast } from "../../services/toastConfig";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // Check if user is already logged in (for session persistence)
  useEffect(() => {
    const storedAuthData = JSON.parse(localStorage.getItem("authData"));
    if (storedAuthData && storedAuthData.token && storedAuthData.user) {
      dispatch(loginSuccess(storedAuthData));
      navigate("/dashboard");
    }
  }, [dispatch, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const { token, user } = await login(username, password);

      // Extract role from user and dispatch it to authSlice
      const userWithRole = {
        ...user,
        role: user.role || "user", // Default role to "user" if not provided
      };

      const authData = { token, user: userWithRole };

      // Save auth data to localStorage
      localStorage.setItem("authData", JSON.stringify(authData));

      dispatch(loginSuccess(authData));
      dispatch(setSelectedOrganization(user.organization));

      showSuccessToast("Login successful");

      dispatch(setActiveComponent({ component: "companyDashboard" }));
      navigate("/dashboard");
    } catch (error) {
      const errorMessage =
        error.message ||
        error.response?.data?.message ||
        "Unexpected error occurred. Please try again.";
      console.error("Login error:", errorMessage);
      dispatch(loginFailure(errorMessage));

      if (errorMessage.includes("Username not found")) {
        showErrorToast("Username not found. Please check and try again.");
      } else if (errorMessage.includes("Incorrect password")) {
        showErrorToast("Incorrect password. Please try again.");
      } else if (errorMessage.includes("Account not validated")) {
        showErrorToast(
          "Account not validated. Check your email for validation instructions."
        );
      } else {
        showErrorToast(`Login failed: ${errorMessage}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  console.log('Email address (username): ', username)

  return (
    <div className="login-page-container">
      <div className="login-logo-container"></div>
      <div className="login-page-form">
        <h2 className="login-page-title">Login</h2>
        <input
          className="login-page-input"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <input
          className="login-page-input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          className="login-page-button"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </button>
        <p className="forgot-password-link" onClick={handleForgotPassword}>
          Forgot Password?
        </p>
      </div>
    </div>
  );
};

export default Login;

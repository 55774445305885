const fetchAdditionalDocuments = async (companyId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/documents/additional?companyId=${companyId}`);

    if (!response.ok) {
      throw new Error("Failed to fetch additional documents");
    }

    const data = await response.json();

    return data.map(doc => ({
      document_id: doc.document_id,
      document_name: doc.document_name,
      file_path: doc.file_path, // Include file_path here
      section: doc.section
    }));
  } catch (error) {
    console.error("Error fetching additional documents:", error);
    return []; // Return an empty array on error
  }
};

export default fetchAdditionalDocuments;

// src/services/decryptEmail.js
import CryptoJS from 'crypto-js';

// Function to decrypt the email
export const decryptEmail = (encryptedEmail) => {
  if (!encryptedEmail) {
    console.warn('decryptEmail: No email provided for decryption');
    return 'unavailable';
  }

  try {
    const [ivHex, encryptedTextHex] = encryptedEmail.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encryptedText = CryptoJS.enc.Hex.parse(encryptedTextHex);
    const encryptionKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);

    const bytes = CryptoJS.AES.decrypt(
      { ciphertext: encryptedText },
      encryptionKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedEmail || 'unavailable';
  } catch (error) {
    console.error("Frontend Decryption - Error decrypting email:", error);
    return 'unavailable';
  }
};

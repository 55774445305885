// Sidebar.js
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveComponent } from "../../redux/slices/activeComponentSlice";
import fetchSidebarData from "../../services/fetchSidebarData";
import "./Sidebar.css";

const Sidebar = ({ franchiseId, stateId, companyId }) => {
  const dispatch = useDispatch();
  const [openSections, setOpenSections] = useState({});
  const [sidebarData, setSidebarData] = useState([]);
  const [loading, setLoading] = useState(true);

  const userRole = useSelector((state) => state.auth.user?.role);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const fetchedData = await fetchSidebarData(
          franchiseId,
          stateId,
          companyId
        );
        setSidebarData(fetchedData);
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (franchiseId && stateId && companyId) {
      setLoading(true);
      fetchDocuments();
    } else {
      setSidebarData([]);
    }
  }, [franchiseId, stateId, companyId]);

  const toggleSection = (section) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  const handleItemClick = (componentName, documentName = "", filePath = "") => {
    // Use `filePath` directly without encoding to avoid double-encoding issues
    dispatch(
      setActiveComponent({ component: componentName, documentName, filePath })
    );
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-section">
        <div
          className="sidebar-section-title"
          onClick={() => handleItemClick("companyDashboard")}
        >
          <div className="section-title-content">
            <span className="material-symbols-outlined">dashboard</span>
            <span className="sidebar-section-name">Company Dashboard</span>
          </div>
        </div>
      </div>

      {(userRole === "admin" || userRole === "account manager") && (
        <div className="sidebar-section">
          <div
            className="sidebar-section-title"
            onClick={() => handleItemClick("documentManager")}
          >
            <div className="section-title-content">
              <span className="material-symbols-outlined">folder_shared</span>
              <span className="sidebar-section-name">Document Manager</span>
            </div>
          </div>
        </div>
      )}
      {userRole === "admin" && (
        <div className="sidebar-section">
          <div
            className="sidebar-section-title"
            onClick={() => handleItemClick("userManager")}
          >
            <div className="section-title-content">
              <span className="material-symbols-outlined">person</span>
              <span className="sidebar-section-name">User Manager</span>
            </div>
          </div>
        </div>
      )}
      {loading ? (
        <div className="no-company-selected">
          Please select a company to display the documents
        </div>
      ) : (
        Object.entries(sidebarData).map(([sectionName, sectionData], index) => (
          <div key={index} className="sidebar-section">
            <div
              className="sidebar-section-title"
              onClick={() => toggleSection(sectionName)}
            >
              <div className="section-title-content">
                <span className="material-symbols-outlined">
                  {sectionData.icon || "folder"}
                </span>
                <span className="sidebar-section-name">{sectionName}</span>
              </div>
              <span className="material-symbols-outlined arrow-icon">
                {openSections[sectionName]
                  ? "arrow_drop_up"
                  : "arrow_drop_down"}
              </span>
            </div>
            {openSections[sectionName] && (
              <ul className="sidebar-items">
                {sectionData.items.map((item, idx) => (
                  <li
                    key={idx}
                    className="sidebar-item"
                    onClick={() =>
                      handleItemClick(
                        "documentViewer",
                        item.name,
                        item.filePath
                      )
                    }
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))
      )}
    </aside>
  );
};

export default Sidebar;

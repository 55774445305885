// src/services/authService.js
import axios from 'axios';

// Token validation
export const validateMagicLinkToken = async (token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/validate-token`, { token });
    return response.data; // Return the response data from the backend
  } catch (error) {
    // Log the error for debugging purposes
    console.error('Token validation error:', error.response?.data?.message || error.message);
    throw new Error('Invalid or expired token'); // Re-throw the error for the component to handle
  }
};

// Login Authentication
export const login = async (username, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
      username,
      password,
    });
    return response.data; // Return the user data from the backend
  } catch (error) {
    console.error("Login error:", error.response?.data?.message || error.message);
    
    // Pass the backend error message to be handled by the calling component
    const errorMessage = error.response?.data?.message || "Unexpected error occurred. Please try again.";
    throw new Error(errorMessage);
  }
};
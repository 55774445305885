// src/services/sectionService.js
const apiUrl = process.env.REACT_APP_API_BASE_URL; // Base URL for the API

// Function to fetch all sections
export const fetchSections = async () => {
  try {
    const response = await fetch(`${apiUrl}/sidebar-sections/sections`);
    if (!response.ok) {
      throw new Error("Failed to fetch sections");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching sections:", error);
    throw error;
  }
};

// Function to add a new section
export const addSection = async (section) => {
  try {
    const response = await fetch(`${apiUrl}/sidebar-sections/sections`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ section }),
    });
    if (!response.ok) {
      throw new Error("Failed to add section");
    }
    return await response.json();
  } catch (error) {
    console.error("Error adding section:", error);
    throw error;
  }
};

import React, { useState } from "react";
import DocumentModal from "../DocumentModal/DocumentModal";
import "./UploadedDocumentsTable.css";

const UploadedDocumentsTable = ({ documents, fetchDocuments }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");

  const handleViewClick = (url) => {
    setDocumentUrl(url);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDocumentUrl("");
  };

  const handleDeleteClick = async (documentId) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/documents/${documentId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          alert("Document deleted successfully");
          fetchDocuments(); // Refresh the document list after deletion
        } else {
          alert("Failed to delete the document. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="uploaded-documents-table">
      <table>
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Upload Date</th>
            <th>View Document</th>
            <th>Remove Document</th>
          </tr>
        </thead>
      </table>
      <div className="scrollable-tbody">
        <table>
          <tbody>
            {documents.length > 0 ? (
              documents.map((doc, idx) => (
                <tr key={idx}>
                  <td>{doc.document_name}</td>
                  <td>{new Date(doc.created_at).toLocaleDateString()}</td>
                  <td>
                    <button
                      className="icon-button view-button"
                      onClick={() => handleViewClick(doc.file_path)}
                    >
                      <span className="material-symbols-outlined">
                        visibility
                      </span>
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon-button remove-button"
                      onClick={() => handleDeleteClick(doc.document_id)} // Pass the document ID
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  style={{ textAlign: "center", padding: "1rem" }}
                >
                  No documents available for this category.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DocumentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        documentUrl={documentUrl}
      />
    </div>
  );
};

export default UploadedDocumentsTable;

import React, { useState } from 'react';
import AddDocument from '../../components/AddDocument/AddDocument';
import ViewDocuments from '../../components/ViewDocuments/ViewDocuments';
import './DocumentManager.css';

const DocumentManager = () => {
  const [documentType, setDocumentType] = useState('Select document type');

  return (
    <div className="document-manager">
      <h2 className="document-manager-title">Document Manager</h2>
      <AddDocument 
        documentType={documentType} 
        setDocumentType={setDocumentType} 
      />
      <ViewDocuments documentType={documentType} />
    </div>
  );
};

export default DocumentManager;

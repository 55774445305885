import { PDFDocument, StandardFonts, rgb, PDFName, PDFString } from "pdf-lib";
import * as pdfjsLib from "pdfjs-dist";
import fontkit from "@pdf-lib/fontkit";

// Set the worker source (adjust the path if needed)
pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

/**
 * Prepopulate a PDF by updating its interactive form fields.
 * Adds custom font styling for the Signature field using a custom embedded font.
 *
 * @param {string} pdfUrl - URL of the original PDF.
 * @param {Object} prepopulateData - An object mapping PDF field names to new text values.
 * @param {string} [signature] - Optional signature value for the 'Signature' field.
 * @returns {Promise<Uint8Array>} The updated PDF bytes.
 */
export async function prepopulatePdf(
  pdfUrl,
  prepopulateData = {},
  signature = ""
) {
  try {
    // Fetch the existing PDF bytes from the URL
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    );

    console.log("PDF URL: ", pdfUrl);

    // Load the PDF document and register fontkit
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);

    // Get the form from the PDF document
    const form = pdfDoc.getForm();

    // Load custom signature font
    const customFontBytes = await fetch("/fonts/Pacifico-Regular.ttf").then(
      (res) => res.arrayBuffer()
    );
    const customFont = await pdfDoc.embedFont(customFontBytes);

    // Insert Signature Logic with Custom Font
    if (signature) {
      try {
        const signatureField = form.getTextField("Signature");
        if (signatureField) {
          signatureField.setText(signature);
          signatureField.updateAppearances(customFont);
          signatureField.defaultUpdateFontSize = 12;
          console.log(`Inserted signature with Pacifico font: "${signature}"`);
        } else {
          console.warn("Signature field not found in the PDF.");
        }
      } catch (error) {
        console.error("Error inserting signature:", error);
      }
    }

    // Prepopulate form fields using a flexible matching strategy
    for (const [fieldName, newValue] of Object.entries(prepopulateData)) {
      try {
        console.log(
          `🔍 Trying to set field: "${fieldName}" with value: "${newValue}"`
        );

        // Get all fields and log them for debugging
        const allFields = form.getFields();
        console.log("📑 Available fields in PDF:");
        allFields.forEach((field) => {
          console.log(`- Field Name: "${field.getName()}"`);
        });

        // Normalize the field name to lowercase for comparison
        const normalizedFieldName = fieldName.toLowerCase();

        // Find a field whose name either exactly matches or contains the given field name (case-insensitive)
        const matchingFields = allFields.filter((field) => {
          const fieldNameInPdf = field.getName().toLowerCase();

          // Extract the core field name after "hc_" (e.g., hc_contact_Page3_1743587776922_5452 -> hc_contact)
          const match = fieldNameInPdf.match(/hc_([a-z_]+)/);
          const coreFieldName = match ? match[0] : fieldNameInPdf;

          console.log(
            `🔗 Comparing (case-insensitive): "${coreFieldName}" (PDF) with "${normalizedFieldName}" (Data)`
          );

          // Check for exact match or substring match
          const isMatch =
            coreFieldName === normalizedFieldName ||
            coreFieldName.includes(normalizedFieldName);

          console.log(
            `✅ Match Found: ${isMatch} for field "${coreFieldName}"`
          );
          return isMatch;
        });

        // Iterate over all matching fields and update each one
        if (matchingFields.length > 0) {
          matchingFields.forEach((matchingField) => {
            try {
              matchingField.setText(newValue);
              matchingField.acroField.dict.set(
                PDFName.of("V"),
                PDFString.of(newValue)
              );
              console.log(
                `✅ Successfully updated field "${matchingField.getName()}" to: "${newValue}"`
              );
            } catch (error) {
              console.error(
                `🚨 Error updating field "${matchingField.getName()}":`,
                error
              );
            }
          });
        } else {
          console.warn(`❗ No matching field found for: "${fieldName}"`);
        }
      } catch (error) {
        console.error(`🚨 Error updating field "${fieldName}":`, error);
      }
    }

    // Retrieve all form fields for enhanced prepopulation logic
    const allFields = form.getFields();
    console.log(`Total form fields found: ${allFields.length}`);

    // Embed Helvetica font for general text
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Improved Signature Font Loading with Error Handling
    let signatureFont;
    try {
      const customFontBytes = await fetch("/fonts/Pacifico-Regular.ttf").then(
        async (res) => {
          if (!res.ok) throw new Error(`Failed to fetch font: ${res.status}`);
          const buffer = await res.arrayBuffer();
          return buffer;
        }
      );
      signatureFont = await pdfDoc.embedFont(customFontBytes);
      console.log("✅ Signature font successfully embedded.");
    } catch (error) {
      console.error("❌ Error embedding custom signature font:", error);
      signatureFont = helveticaFont; // Fallback to Helvetica if custom font fails
    }

    // Define a placeholder mapping for known default values
    const placeholderMapping = {
      "Enter City Here": "hc_addresscity",
      "Enter Email Here": "hc_email",
      "Enter Name Here": "new_name",
      "Enter Address Here": "hc_address",
      "Enter Postal Code Here": "hc_addresszippostalcode",
      Today: "Date", // For Date fields
      "Sign Here": "Signature", // For Signature fields
    };
    // Array of known placeholder texts
    const placeholders = Object.keys(placeholderMapping);

    // Enhanced Handling for Duplicate Fields (fields with appended _<number>)
    allFields.forEach((field, index) => {
      const rawFieldName = field.getName();
      // Remove trailing underscore and digits (e.g., "Hc_addresscity_1" becomes "Hc_addresscity")
      const baseFieldName = rawFieldName.replace(/_\d+$/, "");
      // Retrieve the corresponding value from prepopulateData using the base field name
      const dataValue = prepopulateData[baseFieldName];

      // Identify field type and whether it is a text field or annotation
      const fieldType = field.constructor.name;
      const isTextField = fieldType === "PDFTextField";
      const isAnnotation = !!field.acroField;

      // Extract current field text
      let currentText = "";
      if (isTextField) {
        currentText = field.getText() || "";
      } else if (isAnnotation) {
        currentText =
          field.acroField.dict.get(PDFName.of("V"))?.value ||
          field.acroField.dict.get(PDFName.of("TU"))?.value ||
          field.acroField.dict.get(PDFName.of("DA"))?.value ||
          "";
      }
      if (typeof currentText !== "string") {
        console.warn(
          `❗ Field "${rawFieldName}" has non-string data:`,
          currentText
        );
        currentText = String(currentText);
      }

      // Log details for debugging
      console.log(`[${index + 1}] Field Found:`, {
        fieldName: rawFieldName,
        baseFieldName: baseFieldName,
        currentValue: currentText,
        fieldType: fieldType,
        isTextField: isTextField,
        isAnnotation: isAnnotation,
      });

      // Check for Date placeholders (empty, "date", or "today")
      if (
        !currentText ||
        currentText.trim() === "" ||
        currentText.trim().toLowerCase() === "date" ||
        currentText.trim().toLowerCase() === "today"
      ) {
        const today = new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });

        if (isTextField) {
          field.setText(today);
        } else if (isAnnotation && field.acroField) {
          field.acroField.dict.set(PDFName.of("V"), PDFString.of(today));
        }
        console.log(`✅ Updated "${rawFieldName}" with today's date: ${today}`);

        if (typeof field.updateAppearances === "function") {
          console.log("✅ Field supports updateAppearances");
          field.updateAppearances(helveticaFont);
        } else {
          console.warn(
            "❌ Field does not support updateAppearances:",
            field.constructor.name
          );
        }

        console.log(`✅ Updated "${rawFieldName}" with today's date: ${today}`);
      }
      // Handling Signature Fields
      else if (
        rawFieldName.toLowerCase().includes("signature") ||
        currentText === "Sign Here"
      ) {
        const signatureValue = prepopulateData["Signature"] || signature;
        if (signatureValue) {
          if (isTextField) {
            field.setText(signatureValue);
          } else if (isAnnotation && field.acroField) {
            field.acroField.dict.set(
              PDFName.of("V"),
              PDFString.of(signatureValue)
            );
          }

          if (typeof field.updateAppearances === "function") {
            console.log(
              `✅ Field "${rawFieldName}" supports updateAppearances.`
            );
            field.updateAppearances(signatureFont);
          } else {
            console.warn(
              `❌ Field "${rawFieldName}" does not support updateAppearances. Skipping styling.`
            );
          }

          console.log(`✅ Signature inserted as: "${signatureValue}"`);
        } else {
          console.warn(
            `❗ Signature field "${rawFieldName}" found, but no data available.`
          );
        }
      }

      // Handling Duplicate Fields:
      // If the field is a duplicate (i.e., its base field name exists in prepopulateData)
      // and it is empty or contains a known placeholder, update it with the correct value.
      else if (
        dataValue &&
        (currentText.trim() === "" || placeholders.includes(currentText))
      ) {
        if (isTextField) {
          field.setText(dataValue);
        } else if (isAnnotation && field.acroField) {
          field.acroField.dict.set(PDFName.of("V"), PDFString.of(dataValue));
        }
        if (typeof field.updateAppearances === "function") {
          console.log("✅ Field supports updateAppearances");
          field.updateAppearances(helveticaFont);
        } else {
          console.warn(
            "❌ Field does not support updateAppearances:",
            field.constructor.name
          );
        }
        console.log(
          `✅ Updated duplicate field "${rawFieldName}" with value: "${dataValue}"`
        );
      } else {
        console.warn(
          `❗ Field "${rawFieldName}" already has content: "${currentText}"`
        );
      }
    });

    // Save and return the updated PDF bytes
    const pdfBytes = await pdfDoc.save();
    console.log("Final updated PDF bytes length:", pdfBytes.byteLength);
    return pdfBytes;
  } catch (error) {
    console.error("Error prepopulating PDF:", error);
    throw error;
  }
}

/**
 * (Optional) Retrieve general PDF metadata.
 *
 * @param {string} pdfUrl - The URL of the PDF.
 * @returns {Promise<Object>} The metadata object.
 */
export async function getPdfMetadata(pdfUrl) {
  try {
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    const pdfDoc = await loadingTask.promise;
    const metadata = await pdfDoc.getMetadata();
    console.log("PDF metadata:", metadata);
    return metadata;
  } catch (error) {
    console.error("Error fetching PDF metadata:", error);
    return null;
  }
}

/**
 * Logs a simplified version of form field annotations from the PDF located at pdfUrl.
 * Only logs the fieldName, fieldValue, textContent, rect, and pageNumber.
 *
 * @param {string} pdfUrl - The URL of the PDF.
 */
export async function logPdfFormFields(pdfUrl) {
  try {
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    const pdfDoc = await loadingTask.promise;
    const numPages = pdfDoc.numPages;
    let allFields = [];
    for (let i = 1; i <= numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const annotations = await page.getAnnotations();
      annotations.forEach((annotation) => {
        if (annotation.subtype === "Widget" && annotation.fieldName) {
          allFields.push(annotation);
        }
      });
    }
    const simplifiedFields = allFields.map((field) => ({
      fieldName: field.fieldName,
      fieldValue: field.fieldValue,
      textContent: field.textContent,
      rect: field.rect,
      pageNumber: field.pageNumber,
    }));
    console.log("PDF Form Fields:", JSON.stringify(simplifiedFields, null, 2));
  } catch (error) {
    console.error("Error logging PDF form fields:", error);
  }
}
